import { mapPatient } from '@/mappers/wizard/patient'
import { mapEncounter } from '@/mappers/wizard/encounter'
import { mapValuesToAdditionalProperties } from '@/mappers/wizard/additionalInformation'
import { mapInsurance } from '@/mappers/wizard/insurance'
import { mapCorrelationResponseToProvider } from '@/mappers/wizard/httpResponseToProvider'
import { mapCorrelationResponseToFacility } from '@/mappers/wizard/httpResponseToFacility'
import * as WizardModels from '../../../models/wizard/fe'

type ProviderFacility = 'provider' | 'facility'

const insuranceSort = (a: any, b: any): number => {
  if (a.primary || b.primary) {
    return a.primary ? -1 : 1
  }
  return 1
}

const mapInsurances = (data: any[], overrides: Record<string, any>): any[] =>
  data?.sort(insuranceSort).map((insurance: any) => {
    const payerUniqueId =
      overrides?.['payer'] && insurance.primary ? overrides?.['payer'] : insurance.unique_id ?? ''
    const planUniqueId =
      overrides?.['plan'] && insurance.primary
        ? overrides?.['plan']
        : insurance.plan?.unique_id ?? ''
    const planState =
      overrides?.['state'] && insurance.primary
        ? overrides?.['state']
        : insurance.plan?.state ?? null
    return mapInsurance({
      ...insurance,
      unique_id: payerUniqueId,
      plan: { ...insurance.plan, unique_id: planUniqueId, state: planState },
    })
  }) || []

const mapProvidersFacilities = (data: any, type: ProviderFacility) => {
  const formattedData: any = {}
  const dataKeys = Object.keys(data)
  dataKeys.forEach(key => {
    formattedData[key] =
      type === 'provider'
        ? mapCorrelationResponseToProvider(data[key])
        : mapCorrelationResponseToFacility(data[key])
  })
  return formattedData
}

export const defaultMessages = (status?: number) => {
  switch (status) {
    case 400:
      return "Our system is currently unable to find this transaction. Let's get you back to Myndview."
    case 401:
      return "It looks like your authorization has expired. Let's get you back to Myndview."
    case 403:
      return "It looks like your authorization has expired. Let's get you back to Myndview."
    default:
      return 'An error occurred in our system.'
  }
}

export const useFormPopulationMapper = () => ({
  getMappedPatient: (data: any) => mapPatient(data),
  getMappedEncounter: (
    encounter?: any,
    category?: string | null,
    primaryCode?: string | null,
    primaryCodeModifier?: string | null
  ) => mapEncounter(encounter, category, primaryCode, primaryCodeModifier),
  getValueMappedAdditionalInformation: (
    mappedAdditionalInformation: WizardModels.AdditionalInformationProperty[],
    additionalInformationValues?: any
  ) => mapValuesToAdditionalProperties(mappedAdditionalInformation, additionalInformationValues),
  getMappedInsurances: (data: any[], overrides: Record<string, any>) =>
    mapInsurances(data, overrides),
  getMappedProviders: (providers: any) => mapProvidersFacilities(providers, 'provider'),
  getMappedFacilities: (facilities: any) => mapProvidersFacilities(facilities, 'facility'),
})
