import { Encounter as EncounterInForm } from '../../types/form/prior-auth/encounter'
import { CorrelationEncounter } from '@/types/api/prior-auth/encounter'
import { CorrelationEncounterDetails } from '@/types/api/prior-auth/encounter/details'
import { EncounterDetails } from '@/types/form/prior-auth/encounter-details'
import { EncounterInformation } from '@/types/form/prior-auth/encounter-information'
import { Procedure } from '@/types/api/prior-auth/encounter/procedure'

export function mapEncounterDetails(
  details: CorrelationEncounterDetails | null,
  serviceCategory?: string | null
): EncounterDetails | null {
  return !serviceCategory
    ? null
    : ({
        category: serviceCategory,
        specimenCollected: details?.specimen_collected || null,
        specimenCollectedDate: details?.specimen_collected_date
          ? new Date(details.specimen_collected_date)
          : null,
        dosage: details?.dosage || null,
        strength: details?.strength || null,
        frequency: details?.frequency || null,
        quantity: details?.quantity || null,
        refills: details?.refills ?? null,
        administration: details?.administration || null,
        duration: details?.duration || null,
        deaNumber: details?.dea_number || null,
        continuationDate: details?.continuation_date ? new Date(details.continuation_date) : null,
        cost: details?.cost ?? null,
        payment: details?.payment || null,
        rentalStartDate: details?.rental_start_date ? new Date(details.rental_start_date) : null,
        rentalEndDate: details?.rental_end_date ? new Date(details.rental_end_date) : null,
        counselorName: details?.counselor_name || null,
        counselingDate: details?.counseling_date ? new Date(details.counseling_date) : null,
        visits: details?.visits
          ? {
              totalWeeks: details?.visits?.total_weeks ?? null,
              weeklyFrequency: details?.visits?.weekly_frequency ?? null,
            }
          : null,
        pharmacy: details?.pharmacy
          ? {
              name: details?.pharmacy?.name || null,
              address: details?.pharmacy?.address
                ? {
                    lineOne: details?.pharmacy?.address?.line_one || null,
                    lineTwo: details?.pharmacy?.address?.line_two || null,
                    lineThree: details?.pharmacy?.address?.line_three || null,
                    city: details?.pharmacy?.address?.city || null,
                    state: details?.pharmacy?.address?.state || null,
                    zipCode: details?.pharmacy?.address?.zip_code || null,
                  }
                : null,
              phone: details?.pharmacy?.phone || null,
              phoneExtension: details?.pharmacy?.phone_ext || null,
              fax: details?.pharmacy?.fax || null,
            }
          : null,
      } as EncounterDetails)
}

export function mapEncounter(
  encounter?: CorrelationEncounter,
  serviceCategory?: string | null,
  primaryCode?: string | null,
  primaryCodeModifier?: string | null
): EncounterInForm {
  if (!encounter && !serviceCategory && !primaryCode) return {}

  const existingPrimaryProcedure: Procedure | null =
    encounter?.primary_procedure && encounter?.primary_procedure?.code?.slice(0, 5) === primaryCode
      ? encounter.primary_procedure
      : null
  const existingAdditionalProcedure: Procedure | null =
    encounter?.additional_procedures?.find(procedure => {
      return procedure?.code?.slice(0, 5) === primaryCode
    }) || null

  const procedure: Procedure | null =
    existingPrimaryProcedure || existingAdditionalProcedure || null
  const primaryProcedure: any = procedure
    ? { ...procedure, code_modifier: procedure?.code_modifier || primaryCodeModifier || null }
    : { code: primaryCode, code_modifier: primaryCodeModifier }
  const additionalProcedures: any =
    encounter?.additional_procedures?.filter(p => p.code !== primaryProcedure?.code) || []

  return {
    procedures: {
      primaryProcedure: {
        startDate: primaryProcedure?.start_date ? new Date(primaryProcedure?.start_date) : null,
        endDate: primaryProcedure?.end_date ? new Date(primaryProcedure?.end_date) : null,
        procedureCode: primaryProcedure?.code?.slice(0, 5) || null,
        codeDescription: primaryProcedure?.code_description || null,
        codeModifier: primaryProcedure?.code_modifier || null,
        units: primaryProcedure?.units || null,
        diagnoses: {
          primaryDiagnosis: primaryProcedure?.primary_diagnosis || null,
          additionalDiagnoses: primaryProcedure?.additional_diagnoses || [],
        },
      },
      additionalProcedures:
        additionalProcedures?.map((procedure: any) => {
          return {
            startDate: procedure.start_date ? new Date(procedure.start_date) : null,
            endDate: procedure.end_date ? new Date(procedure.end_date) : null,
            procedureCode: procedure.code?.slice(0, 5) || null,
            codeModifier: procedure.code_modifier || procedure.code?.slice(5, 7) || null,
            codeDescription: procedure.code_description || null,
            units: procedure.units || null,
            diagnoses: {
              primaryDiagnosis: procedure.primary_diagnosis || null,
              additionalDiagnoses: procedure.additional_diagnoses || [],
            },
          }
        }) || [],
    },
    information: {
      locationType: encounter?.location_type || null,
      serviceType: encounter?.service_type || null,
      inpatient: encounter?.inpatient || false,
    } as EncounterInformation,
    details: mapEncounterDetails(encounter?.details || {}, serviceCategory),
  }
}
