import { Commit } from 'vuex'
import axios from 'axios'

import { useFormPopulationRequest } from '@/modules/form-population/http'
import { defaultMessages } from '@/modules/form-population/mapper'
import { getFeatureFlags as fetchFeatureFlags } from '@/modules/features/http'
import { getEncounterProcedures } from '@/modules/encounters/http'
import { getBFFRoute, getMyndviewUrl } from '@/modules/helpers/http'
import { expireAllCookies } from '@/modules/cookies/cookies'

import { State } from '@/store/wizard/state'
import { ErrorState } from '@/types/error-component/error-component'

import { track } from 'logrocket'

const backToMyndviewButton =
  (state: any): any =>
  () => {
    const url = getMyndviewUrl(state.session.clientId, state.session.correlationId)
    expireAllCookies()
    window.onbeforeunload = null
    window.location.assign(url)
  }

const checkActive = async ({
  rootState,
  commit,
  state,
}: {
  rootState: any
  commit: Commit
  state: State
}): Promise<boolean> => {
  const clientId = state?.session?.clientId || ''
  const token = state?.session?.token || ''
  const csrf = rootState?.csrf

  if (!clientId || !token || !csrf) {
    return false
  } else {
    try {
      const path = `${getBFFRoute()}/api/authorize/check-active`
      const response = await axios.get(path, {
        headers: {
          Authorization: `Bearer ${token}`,
          'x-csrf-token': rootState?.csrf,
          'x-myndshft-client-id': clientId,
          'content-type': 'application/json',
        },
        params: {
          date: new Date().toISOString(),
        },
      })

      if (response.status === 200) {
        commit('setLastActive', response?.data?.date || null)

        if (response?.data?.reVerify) {
          commit('setReVerify', response.data.reVerify)
        }

        return !!response?.data?.date
      } else {
        return false
      }
    } catch (_error) {
      return false
    }
  }
}

const setActive = async ({
  rootState,
  commit,
  state,
}: {
  rootState: any
  commit: Commit
  state: State
}): Promise<boolean> => {
  const clientId = state?.session?.clientId || ''
  const token = state?.session?.token || ''
  const csrf = rootState?.csrf
  const date = new Date()

  if (!clientId || !token || !csrf) {
    return false
  } else {
    try {
      const path = `${getBFFRoute()}/api/authorize/set-active`
      const response = await axios.get(path, {
        headers: {
          Authorization: `Bearer ${token}`,
          'x-csrf-token': rootState?.csrf,
          'x-myndshft-client-id': clientId,
          'content-type': 'application/json',
        },
        params: {
          date: date.toISOString(),
        },
      })

      if (response.status === 200) {
        commit('setLastActive', date)

        if (response?.data?.reVerify) {
          commit('setReVerify', response.data.reVerify)
        }
      }

      return response.status === 200
    } catch (_error) {
      return false
    }
  }
}

const createSession = async (
  { rootState, commit, state }: { rootState: any; commit: Commit; state: State },
  idToken: string
): Promise<boolean> => {
  const clientId = state?.session?.clientId || ''
  if (!clientId) {
    return false
  } else {
    try {
      const path = `${getBFFRoute()}/api/authorize/create-session`
      const response = await axios.post(
        path,
        { id: idToken },
        {
          headers: {
            'x-csrf-token': rootState?.csrf,
            'x-myndshft-client-id': clientId,
            'content-type': 'application/json',
          },
        }
      )
      const token = response?.data?.token

      if (token) {
        commit('setToken', token)
      }

      return !!token
    } catch (error) {
      return false
    }
  }
}

const getTransactionData = async ({
  rootState,
  commit,
  state,
  dispatch,
}: {
  rootState: any
  commit: Commit
  state: State
  dispatch: any
}) => {
  const token = state?.session?.token || ''
  const csrf = rootState.csrf || ''
  const clientId = state?.session?.clientId || ''
  const correlationId = state?.session?.correlationId || ''

  if (token && clientId && correlationId) {
    try {
      const { getFormPopulationData } = useFormPopulationRequest(token, clientId, csrf)

      commit('setLoadingTransaction', true)

      const formPopData = await getFormPopulationData(correlationId)

      if (formPopData) {
        const networkStatus = !!formPopData?.providers?.servicing?.in_network

        commit('setPrepopulationData', formPopData)
        commit('setErrorMessage', '')
        commit('setErrorButtonMessage', '')
        commit('setNetworkStatus', networkStatus)
      } else {
        track('FormPopulationFailedEvent', { correlationId, clientId })
      }
    } catch (err: any) {
      // This handles returning the API message for when a transaction has already been run.
      const status = err?.response?.data?.status
      const defaultMessage = defaultMessages(status)
      commit('setErrorMessage', err?.response?.data?.message || defaultMessage)
      commit('setErrorButtonMessage', 'Back to Myndview')

      const errorSettings: Partial<ErrorState> = {
        messageLineOne: state.errorMessage || undefined,
        buttonLabel: state.errorButtonMessage || undefined,
        action: backToMyndviewButton(state),
      }

      await dispatch('errorComponent/setErrorFromTemplate', errorSettings, { root: true })
      commit('setErrorReported', true)
    }
  }
}

const getProcedureCodes = async ({
  rootState,
  commit,
  state,
  dispatch,
}: {
  rootState: any
  commit: Commit
  state: State
  dispatch: any
}) => {
  const token = state?.session?.token || ''
  const csrf = rootState.csrf || ''
  const clientId = state?.session?.clientId || ''
  const correlationId = state?.session?.correlationId || ''
  commit('setLoadingTransaction', true)

  if (token && clientId && correlationId) {
    try {
      const procedures = await getEncounterProcedures(token, clientId, correlationId, csrf)

      if (!procedures?.codes?.length) {
        // If no codes come back, this means no par has been run.
        const message =
          'A Prior Authorization Requirement transaction is needed to determine if a PA is required and to supply any needed PA Submission details.'
        commit('setErrorMessage', message)
        commit('setErrorShouldAddCorrelation', true)
        commit(
          'setErrorButtonMessage',
          'Run a Prior Authorization Requirements Transaction in Myndview'
        )

        const errorSettings: Partial<ErrorState> = {
          messageLineOne: state.errorMessage || undefined,
          buttonLabel: state.errorButtonMessage || undefined,
          action: backToMyndviewButton(state),
        }

        await dispatch('errorComponent/setErrorFromTemplate', errorSettings, { root: true })
        commit('setErrorReported', true)
      } else {
        commit('setProcedures', procedures || {})
      }
    } catch (err: any) {
      // This catches any potential errors in which fetching procedures results in not found.
      const message = 'PA submission is not supported for this payer and plan combination.'
      commit('setErrorMessage', message)
      commit('setErrorShouldAddCorrelation', true)
      commit('setErrorButtonMessage', 'Back to Myndview')

      const errorSettings: Partial<ErrorState> = {
        messageLineOne: state.errorMessage || undefined,
        buttonLabel: state.errorButtonMessage || undefined,
        action: backToMyndviewButton(state),
      }

      await dispatch('errorComponent/setErrorFromTemplate', errorSettings, { root: true })
      commit('setErrorReported', true)
    }
  }
}

const setCorrelationId = ({ commit, state }: { commit: Commit; state: State }, value: string) => {
  if (value) {
    commit('setCorrelationId', value)
  } else if (state.session.token) {
    commit(
      'setErrorMessage',
      `A correlation id is needed to determine if a PA is required 
        and to supply any needed PA Submission details.`
    )
    commit(
      'setErrorButtonMessage',
      `Run a Prior Authorization Requirements Transaction in Myndview`
    )
  }
}

const getFeatureFlags = async ({
  rootState,
  commit,
  state,
}: {
  rootState: any
  commit: Commit
  state: State
}) => {
  const clientID = state?.session?.clientId || ''
  const email = state?.userEmail || ''
  const csrf = rootState?.csrf || ''
  const flags = await fetchFeatureFlags(clientID, email, csrf)
  commit('setFeatureFlags', flags || [])
}

const verifySession = async ({
  rootState,
  commit,
  state,
}: {
  rootState: any
  commit: Commit
  state: State
}): Promise<boolean> => {
  const clientId = state?.session?.clientId || ''
  const csrf = rootState?.csrf || ''
  if (!clientId || !csrf) {
    return false
  } else {
    try {
      const path = `${getBFFRoute()}/api/authorize/verify-session`
      const response = await axios.get(path, {
        headers: {
          'x-myndshft-client-id': clientId,
          'x-csrf-token': csrf,
          'content-type': 'application/json',
        },
      })
      const token = response?.data?.token

      if (token) {
        commit('setToken', token)
        commit('setReVerify', false)
      }

      return !!token
    } catch (error) {
      return false
    }
  }
}

const sessionLogout = async ({
  rootState,
  commit,
  state,
}: {
  rootState: any
  commit: Commit
  state: State
}): Promise<boolean> => {
  const token = state?.session?.token || ''
  const clientId = state?.session?.clientId || ''
  const csrf = rootState?.csrf || ''
  if (!clientId || !csrf || !token) {
    return false
  } else {
    try {
      const path = `${getBFFRoute()}/api/authorize/session-logout`
      const response = await axios.get(path, {
        headers: {
          Authorization: `Bearer ${token}`,
          'x-myndshft-client-id': clientId,
          'x-csrf-token': csrf,
          'content-type': 'application/json',
        },
      })

      if (response.status === 200) {
        commit('clearSession')
      }

      return response.status === 200
    } catch (error) {
      return false
    }
  }
}

const getServiceTypeOptions = async ({
  rootState,
  commit,
  state,
}: {
  rootState: any
  commit: Commit
  state: State
}): Promise<boolean> => {
  const token = state?.session?.token || ''
  const clientId = state?.session?.clientId || ''
  const csrf = rootState?.csrf || ''
  if (!csrf || !token) {
    return false
  } else {
    try {
      const path = `${getBFFRoute()}/api/service_types`
      const response = await axios.get(path, {
        headers: {
          Authorization: `Bearer ${token}`,
          'x-csrf-token': csrf,
          'x-myndshft-client-id': clientId,
          'content-type': 'application/json',
        },
      })

      if (response.status === 200 && response?.data?.types) {
        commit('setServiceTypeOptions', response.data.types)
      }

      return response.status === 200
    } catch (error) {
      return false
    }
  }
}

export const actions = {
  checkActive,
  createSession,
  getTransactionData,
  getProcedureCodes,
  getServiceTypeOptions,
  getFeatureFlags,
  setActive,
  setCorrelationId,
  sessionLogout,
  verifySession,
}
