import { Commit } from 'vuex'

import { PatientInformation } from '@/types/form/prior-auth/patient-information'
import { Address } from '@/types/api/prior-auth/common/address'
import { Insurance } from '@/types/form/prior-auth/insurance'
import { SubmissionDetails } from '@/types/form/prior-auth/submission-details'
import { ProviderInformation } from '@/types/form/prior-auth/provider-information'
import { Contact } from '@/types/form/prior-auth/contact'
import { EncounterInformation } from '@/types/form/prior-auth/encounter-information'
import { Procedures } from '@/types/form/prior-auth/procedures'

export const actions = {
  setSubmissionDetails({ commit }: { commit: Commit }, value: SubmissionDetails): void {
    commit('setSubmissionDetails', value)
  },
  setPatientInformation({ commit }: { commit: Commit }, value: PatientInformation): void {
    commit('setPatientInformation', value)
  },
  setPatientAddress({ commit }: { commit: Commit }, value: Address): void {
    commit('setPatientAddress', value)
  },
  setPatientDrugAllergies({ commit }: { commit: Commit }, value: Array<string>): void {
    commit('setPatientDrugAllergies', value)
  },
  setPatientInsurance({ commit }: { commit: Commit }, value: Insurance): void {
    commit('setPatientInsurance', value)
  },
  setOrderingProviderInformation({ commit }: { commit: Commit }, value: ProviderInformation): void {
    commit('setOrderingProviderInformation', value)
    // TODO: Update ordering facility if using provider information
  },
  setOrderingProviderContact({ commit }: { commit: Commit }, value: Contact): void {
    commit('setOrderingProviderContact', value)
    // TODO: Update ordering facility if using provider contact
  },
  setOrderingProviderAddress({ commit }: { commit: Commit }, value: Address): void {
    commit('setOrderingProviderAddress', value)
    // TODO: Update ordering facility if using provider address
  },
  setServicingProviderInformation(
    { commit }: { commit: Commit },
    value: ProviderInformation
  ): void {
    commit('setServicingProviderInformation', value)
    // TODO: Update servicing facility if using provider information
  },
  setServicingProviderContact({ commit }: { commit: Commit }, value: Contact): void {
    commit('setServicingProviderContact', value)
    // TODO: Update servicing facility if using provider contact
  },
  setServicingProviderAddress({ commit }: { commit: Commit }, value: Address): void {
    commit('setServicingProviderAddress', value)
    // TODO: Update servicing facility if using provider address
  },
  setPCPInformation({ commit }: { commit: Commit }, value: ProviderInformation): void {
    commit('setPCPInformation', value)
  },
  setPCPContact({ commit }: { commit: Commit }, value: Contact): void {
    commit('setPCPContact', value)
  },
  setPCPAddress({ commit }: { commit: Commit }, value: Address): void {
    commit('setPCPAddress', value)
  },
  resetPCP({ commit }: { commit: Commit }): void {
    commit('resetPCP')
  },
  setReferringInformation({ commit }: { commit: Commit }, value: ProviderInformation): void {
    commit('setReferringInformation', value)
  },
  setReferringContact({ commit }: { commit: Commit }, value: Contact): void {
    commit('setReferringContact', value)
  },
  setReferringAddress({ commit }: { commit: Commit }, value: Address): void {
    commit('setReferringAddress', value)
  },
  resetReferringProvider({ commit }: { commit: Commit }): void {
    commit('resetReferringProvider')
  },
  setOrderingFacilityInformation({ commit }: { commit: Commit }, value: ProviderInformation): void {
    commit('setOrderingFacilityInformation', value)
  },
  setOrderingFacilityContact({ commit }: { commit: Commit }, value: Contact): void {
    commit('setOrderingFacilityContact', value)
  },
  setOrderingFacilityAddress({ commit }: { commit: Commit }, value: Address): void {
    commit('setOrderingFacilityAddress', value)
  },
  setServicingFacilityInformation(
    { commit }: { commit: Commit },
    value: ProviderInformation
  ): void {
    commit('setServicingFacilityInformation', value)
  },
  setServicingFacilityContact({ commit }: { commit: Commit }, value: Contact): void {
    commit('setServicingFacilityContact', value)
  },
  setServicingFacilityAddress({ commit }: { commit: Commit }, value: Address): void {
    commit('setServicingFacilityAddress', value)
  },
  setEncounter({ commit }: { commit: Commit }, value: EncounterInformation): void {
    commit('setEncounter', value)
  },
  setEncounterProcedures({ commit }: { commit: Commit }, value: Procedures): void {
    commit('setEncounterProcedures', value)
  },
  setEncounterDetails({ commit }: { commit: Commit }, value: EncounterInformation): void {
    commit('setEncounterDetails', value)
  },
  setServicingFacilityProvider({ commit }: { commit: Commit }, value: boolean): void {
    commit('setServicingFacilityProvider', value)
  },
  setOrderingFacilityProvider({ commit }: { commit: Commit }, value: boolean): void {
    commit('setOrderingFacilityProvider', value)
  },
}
