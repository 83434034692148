import * as I from 'io-ts'
import { AxiosError } from 'axios'

/**
 * Represents an error when making an HTTP request.
 */
export class HttpError {
  readonly _tag = 'HttpError'
  constructor(readonly error: AxiosError<any>) {}
}

/**
 * Represents an error that occurs when mapping the prior auth submission.
 */
export class SubmissionMappingError {
  readonly _tag = 'SubmissionMappingError'
  constructor(readonly error: I.Errors) {}
}

/**
 * Represents an error that occurs during runtime I/O validation.
 */
export class RuntimeValidationError {
  readonly _tag = 'RuntimeValidationError'
  constructor(readonly errors: I.Errors) {}
}

/**
 * Represents an error that occurs when reading runtime configuration values.
 */
export class ConfigurationAccessError {
  readonly _tag = 'ConfigurationAccessError'
}
