import { MyndConfiguration } from '@/types/config/environment'

export const state = {
  config: {
    environment: 'local',
    version: null,
    myndviewUrl: '',
    apiServer: '',
    myndcontrol: '',
    pdfMapperApi: '',
    inactivityWarningAfterMilliseconds: null,
    inactivityLogoutAfterMilliseconds: null,
  } as MyndConfiguration,
}

export type State = typeof state
