export enum Environment {
  local = 'local',
  development = 'development',
  test = 'test',
  sandbox = 'sandbox',
  production = 'production',
}
export type EnvironmentKeys = keyof typeof Environment

export interface IdentityProvider {
  id?: string
  type: string
  name: string
}

export interface TenantConfiguration {
  externalId: string
  name: string
  providers: Array<IdentityProvider>
  themeId?: string | null
  whiteLabelToggled?: boolean | null
}

export interface AuthConfiguration {
  apiKey: string
  appId: string
  tenants: Record<string, TenantConfiguration>
}

export interface MyndConfiguration {
  environment: EnvironmentKeys
  myndviewUrl: string
  apiServer: string
  myndcontrol: string
  pdfMapperApi: string
  tenantConfiguration: AuthConfiguration
  version?: string | null
  inactivityWarningAfterMilliseconds?: number | null
  inactivityLogoutAfterMilliseconds?: number | null
}

export function envProjectID(env: EnvironmentKeys) {
  switch (env) {
    case 'local':
      return 'cbclaims-dev2'
    case 'development':
      return 'cbclaims-dev2'
    case 'test':
      return 'cbclaims-test'
    case 'sandbox':
      return 'cbclaims-sandbox'
    case 'production':
      return 'cbclaims-production'
    default:
      throw new Error('Invalid environment')
  }
}
