import { Attachment as APIAttachment } from '@/types/api/prior-auth/common/attachment'
import { AttachmentType, Attachment as UIAttachment } from '@/types/form/prior-auth/attachment'
function mapStringToAttachmentType(attachmentType: string): AttachmentType {
  let type: AttachmentType = AttachmentType.MISC_ATTACHMENT
  if (attachmentType?.toUpperCase() === AttachmentType.HISTORY_AND_PHYSICAL) {
    type = AttachmentType.HISTORY_AND_PHYSICAL
  } else if (attachmentType?.toUpperCase() === AttachmentType.SIGNED_ORDER) {
    type = AttachmentType.SIGNED_ORDER
  }
  return type
}
export function mapAttachment(attachment: APIAttachment): UIAttachment {
  const type: AttachmentType = mapStringToAttachmentType(attachment.type)
  return {
    name: attachment.name,
    type,
    attachmentId: attachment.attachment_id,
  }
}
