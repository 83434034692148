import axios, { AxiosRequestConfig } from 'axios'
import { getBFFRoute } from '@/modules/helpers/http'

const getEncounterProcedures = async (
  jwt: string,
  clientId: string,
  correlationId: string,
  csrf: string
) => {
  const path = `${getBFFRoute()}/api/encounters/${correlationId}`
  const config = {
    responseType: 'json',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'x-myndshft-client-id': clientId,
      'x-csrf-token': csrf,
    },
  } as AxiosRequestConfig
  const response = await axios.get(path, config)
  return response.data
}

export { getEncounterProcedures }
