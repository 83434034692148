<template>
  <div id="container">
    <Auth @received-token="onTokenReceived" @imageFinished="onImageFinished" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import { postAuthorization } from '@/modules/myndcontrol/http'
import { setCookies } from '@/modules/cookies/cookies'

import Auth from './Auth.vue'

export default defineComponent({
  name: 'Authenticate',
  components: {
    Auth,
  },
  emits: ['auth-received', 'image-finished'],
  setup(_, { emit }) {
    const store = useStore()

    const onTokenReceived = async (token: string) => {
      if (
        store.getters['wizard/getFeatureFlags'].has('isEnabledToUseTheSessionCookieToFetchTheToken')
      ) {
        await store.dispatch('wizard/createSession', token)
      } else {
        postAuthorization(token, store.state.csrf)
          .then(result => {
            store.commit('wizard/setToken', result.token)
            setCookies({
              token: result.token,
            })
          })
          .catch(_error => {
            console.error('Unauthorized')
          })
      }
    }

    const onImageFinished = () => emit('image-finished')

    return {
      onTokenReceived,
      onImageFinished,
    }
  },
})
</script>

<style lang="scss" scoped>
#container {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}

#login-options {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: stretch;
  justify-content: center;
}

.login-option-button {
  margin: 5px;
  display: flex;
  flex-basis: 100%;
  flex-grow: 1;
  align-content: center;
  align-items: stretch;
  justify-content: center;
}

.login-option-button > * {
  margin: 0;
  display: flex;
  flex-grow: 1;
  align-content: center;
  align-items: stretch;
  justify-content: center;
}
</style>
