import { Address } from '@/types/form/prior-auth/address'
import { Address as MappedAddress, AddressState } from '@/types/api/prior-auth/common/address'

export function mapAddress(address?: Address | null): MappedAddress | null {
  if (!address || !Object.values(address).some(value => !!value)) {
    return null
  }

  return {
    line_one: address?.lineOne || null,
    line_two: address?.lineTwo || null,
    line_three: address?.lineThree || null,
    city: address?.city || null,
    state: (address?.state as AddressState) || null,
    zip_code: address?.zipCode || null,
  }
}
