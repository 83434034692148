import { GeneticTesting } from '@/types/form/prior-auth/encounter-details'
import { GeneticTesting as MappedGeneticTesting } from '@/types/api/prior-auth/encounter/details/genetic-testing'

export function mapGeneticTesting(geneticTesting: GeneticTesting): MappedGeneticTesting {
  if (geneticTesting.specimenCollected) {
    return {
      category: 'genetic_testing',
      counselor_name: geneticTesting?.counselorName || null,
      counseling_date:
        geneticTesting?.counselingDate != null
          ? geneticTesting?.counselingDate?.toISOString().replace('Z', '+00:00')
          : null,
      specimen_collected: geneticTesting?.specimenCollected,
      specimen_collected_date: geneticTesting?.specimenCollectedDate
        ?.toISOString()
        .replace('Z', '+00:00'),
    }
  }
  return {
    category: 'genetic_testing',
    counselor_name: geneticTesting?.counselorName || null,
    counseling_date:
      geneticTesting?.counselingDate != null
        ? geneticTesting?.counselingDate?.toISOString().replace('Z', '+00:00')
        : null,
    specimen_collected: geneticTesting?.specimenCollected ?? null,
  }
}
