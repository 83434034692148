import { Provider } from '@/types/form/prior-auth/provider'
import { ProviderName } from '@/types/form/prior-auth/provider-name'
import {
  ProviderName as MappedProviderName,
  Provider as MappedProvider,
} from '@/types/api/prior-auth/providers/provider'
import { mapAddress } from './address'

export function mapProviderName(providerName: ProviderName): MappedProviderName {
  switch (providerName?.type) {
    case 'individual':
      return {
        first_name: providerName?.firstName || null,
        last_name: providerName?.lastName || null,
      }

    case 'organization':
      return {
        organization_name: providerName?.organizationName || null,
      }
    default:
      return null
  }
}

export function mapProvider(provider: Provider | null): MappedProvider | null {
  const providerDoesNotHaveValues = !Object.entries(provider || {}).some(([key, value]) => {
    if (key === 'address' && value) {
      return Object.values(value).some(value => !!value)
    }
    return !!value
  })

  if (!provider || providerDoesNotHaveValues) {
    return null
  }

  return {
    in_network: provider.information?.networkStatus
      ? provider.information?.networkStatus === 'IN_NETWORK'
      : null,
    address: mapAddress(provider?.address || null),
    phone: provider?.contact?.phone || null,
    fax: provider?.contact?.fax || null,
    npi: provider?.information?.npi || null,
    tin: provider?.information?.tin || null,
    specialty: provider?.information?.specialty || null,
    contact_name: provider?.contact?.contactName || null,
    phone_ext: provider?.contact?.phoneExtension || null,
    email: provider?.contact?.email || null,
    ...mapProviderName(provider?.information?.providerName || null),
  }
}
