<template>
  <div class="footer">
    <p class="copyright-info" v-if="showCopyright">
      &copy; {{ year }} Myndshft. All rights reserved.
      <a class="wl-link" :href="termsUrl" target="_blank">Terms of Service</a><br />
      <i>Version {{ pasVersion }}</i>
    </p>
    <div class="powered-by" v-if="showPoweredByLogo">
      <div class="powered-by-text">Powered by</div>
      <img class="powered-by-logo" :src="footerUrl" alt="Myndshft Logo" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'PageFooter',
  props: {
    showCopyright: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const store = useStore()
    const pasVersion = computed(() => store.state.config.config.version)
    const termsUrl = computed(() => store.state.config.config.myndviewUrl + '/terms')
    const showPoweredByLogo = computed<boolean>(() => {
      return !['submit', 'pa-submit', 'localhost'].includes(
        window?.location?.hostname?.split('.')?.[0] || ''
      )
    })

    const footerUrl = 'https://storage.googleapis.com/white-labeling/footerLogo.png'
    const year = new Date().getFullYear()

    return {
      year,
      termsUrl,
      footerUrl,
      pasVersion,
      showPoweredByLogo,
    }
  },
})
</script>

<style lang="scss">
@import '@myndshft/styles/src/components/mixins.scss';

.footer {
  margin: 50px 0;
}

.copyright-info {
  text-align: center;
}

.powered-by {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  border: 1px solid $myndshft-medium-orange;
  border-radius: 4px;
  margin: 50px auto 0;
  height: 4.1875em;
  width: 10.75em;

  &-text {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-logo {
    width: 140px;
    height: 26px;
  }
}
</style>
