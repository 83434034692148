import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios'

import { pipe } from 'fp-ts/lib/function'
import * as TE from 'fp-ts/lib/TaskEither'
import * as I from 'io-ts'

import * as Err from './errors'

export const getBFFRoute = () => {
  return window.location.origin.includes('localhost')
    ? `${window.location.origin}`.replace(':8080', ':3300')
    : window.location.origin
}

export const createGetRequest = (url: string, config?: AxiosRequestConfig) =>
  TE.tryCatch(
    () => axios.get(url, config),
    e => new Err.HttpError(e as AxiosError<any>)
  )

export const createPostRequest =
  <T>(url: string) =>
  (data: T, config?: AxiosRequestConfig) =>
    TE.tryCatch(
      () => axios.post(url, data, config),
      e => new Err.HttpError(e as AxiosError<any>)
    )

export const createPutRequest =
  <T>(url: string) =>
  (data: T, config?: AxiosRequestConfig) =>
    TE.tryCatch(
      () => axios.put(url, data, config),
      e => new Err.HttpError(e as AxiosError<any>)
    )

export const decodeResponse =
  <T>(decoder: I.Decoder<unknown, T>) =>
  (response: AxiosResponse<unknown>) =>
    pipe(
      response,
      r => r.data,
      decoder.decode,
      TE.fromEither,
      TE.mapLeft(e => new Err.RuntimeValidationError(e))
    )

/**
 * Only call {fn} one time after {wait} milliseconds
 * @param fn Function to call
 * @param wait Number of milliseconds
 */
export function debounce<T>(fn: T, wait: number) {
  let timer: ReturnType<typeof setTimeout>
  return (event: Event) => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      if (typeof fn === 'function') {
        fn(event)
      }
    }, wait)
  }
}

export function getMyndviewUrl(clientId: string | null, correlationId: string | null) {
  const currentHref = document.location.origin
  const clientParam = clientId ? `?clientId=${clientId}` : ''
  const correlationParam = clientId && correlationId ? `&correlationId=${correlationId}` : ''
  const url = `${currentHref}${clientParam}${correlationParam}`

  if (url.includes('myndview.app')) {
    return url.replace('submit.', '')
  } else if (url.includes('pa-submit')) {
    return url.replace('pa-submit', 'myndview')
  } else if (url.includes('localhost')) {
    return url.replace(':8080', ':4200')
  }
  return url
}

export const getHeaderFromResponse = (header: string, response: any): string | null => {
  return response.headers?.[header] || null
}
