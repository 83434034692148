import * as WizardModels from '../../../models/wizard/fe'

export const MAX_DEPTH = 5

export function isPropertyValueNullOrUndefiend(value?: any): boolean {
  return value === null || value === undefined
}

export function findPropertyById(
  id: string,
  properties: WizardModels.AdditionalInformationProperty[],
  depth?: number
): WizardModels.AdditionalInformationProperty | null {
  const currentDepth = depth ?? 0
  if (currentDepth > MAX_DEPTH) return null

  for (const property of properties) {
    if (property.id === id) return property
    else if (WizardModels.isAdditionalInformationObjectProperty(property)) {
      const propertyFound = findPropertyById(id, property.properties, currentDepth + 1)
      if (propertyFound !== null) return propertyFound
    }
  }
  return null
}

export function updateAdditionalInformation(
  newProperty: WizardModels.AdditionalInformationProperty,
  properties: WizardModels.AdditionalInformationProperty[],
  depth?: number
): WizardModels.AdditionalInformationProperty[] {
  const currentDepth = depth ?? 0
  if (currentDepth > MAX_DEPTH) return []

  const updatedProperties: WizardModels.AdditionalInformationProperty[] = []

  for (const property of properties) {
    if (WizardModels.isAdditionalInformationObjectProperty(property)) {
      const innerProperties = updateAdditionalInformation(
        newProperty,
        property.properties,
        currentDepth + 1
      )
      updatedProperties.push({ ...property, properties: innerProperties })
    } else {
      const tempProperty = property.id === newProperty.id ? { ...newProperty } : { ...property }
      updatedProperties.push(tempProperty)
    }
  }
  return updatedProperties
}

export function validateStringProperty(
  property: WizardModels.AdditionalInformationStringProperty
): boolean {
  return property.value ? !!property.valid : !property.required
}

export function validateNumberProperty(
  property:
    | WizardModels.AdditionalInformationNumberProperty
    | WizardModels.AdditionalInformationIntegerProperty
): boolean {
  return property.value || property.value === 0
    ? !!property.valid
    : !Number.isNaN(property.value) && !property.required
}

export function validateBooleanProperty(
  property: WizardModels.AdditionalInformationBooleanProperty
): boolean {
  return !isPropertyValueNullOrUndefiend(property.value) ? !!property.valid : !property.required
}

export function validateArrayProperty(
  property: WizardModels.AdditionalInformationArrayProperty
): boolean {
  return !property.required || property.value.length >= 1
}

export function validateDateProperty(
  property: WizardModels.AdditionalInformationStringProperty
): boolean {
  return property.value ? !!property.valid : !property.required
}

export function validateEnumProperty(
  property: WizardModels.AdditionalInformationEnumProperty
): boolean {
  return property.value && property.enum.length
    ? property.enum.includes(property.value)
    : !property.required
}

export function validateAdditionalInformation(
  properties: WizardModels.AdditionalInformationProperty[],
  depth?: number
): boolean {
  const currentDepth = depth ?? 0
  if (currentDepth > MAX_DEPTH) return false

  let isValid = true

  for (const property of properties) {
    switch (property.type) {
      case 'object':
        isValid = isValid && validateAdditionalInformation(property.properties, currentDepth + 1)
        break
      case 'string':
        if (WizardModels.isAdditionalInformationDateProperty(property)) {
          isValid = isValid && validateDateProperty(property)
        } else {
          isValid = isValid && validateStringProperty(property)
        }
        break
      case 'number':
      case 'integer':
        isValid = isValid && validateNumberProperty(property)
        break
      case 'boolean':
        isValid = isValid && validateBooleanProperty(property)
        break
      case 'array':
        isValid = isValid && validateArrayProperty(property)
        break
      case 'enum':
        isValid = isValid && validateEnumProperty(property)
        break
      default:
    }
  }
  return isValid
}

export const additionalInformation = (store: any) => {
  const update = (property: WizardModels.AdditionalInformationProperty) => {
    const currentProperties = store.getters['submission/getAdditionalInformation']
    const updatedProperties = updateAdditionalInformation(property, currentProperties)
    store.commit('submission/setAdditionalInformation', updatedProperties)
  }

  const find = (id: string): WizardModels.AdditionalInformationProperty | null => {
    const properties = store.getters['submission/getAdditionalInformation']
    return findPropertyById(id, properties)
  }

  return { find, update }
}
