import { AnyFunction } from '@/types/function-type'

export type AlignmentOptions = 'center' | 'left' | 'right'

export interface ErrorState {
  bannerUrl: string
  bannerTitle: string
  messageTitle: string
  messageLineOne: string
  messageLineTwo: string
  messageLineThree: string
  titleAlign: AlignmentOptions
  messageAlign: AlignmentOptions
  buttonLabel: string
  action: null | AnyFunction
}

export enum ErrorMutationTypes {
  SET_BANNER_URL = 'SET_BANNER_URL',
  SET_BANNER_TITLE = 'SET_BANNER_TITLE',
  SET_MESSAGE_TITLE = 'SET_MESSAGE_TITLE',
  SET_MESSAGE_LINE_ONE = 'SET_MESSAGE_LINE_ONE',
  SET_MESSAGE_LINE_TWO = 'SET_MESSAGE_LINE_TWO',
  SET_MESSAGE_LINE_THREE = 'SET_MESSAGE_LINE_THREE',
  SET_TITLE_ALIGN = 'SET_TITLE_ALIGN',
  SET_MESSAGE_ALIGN = 'SET_MESSAGE_ALIGN',
  SET_BUTTON_LABEL = 'SET_BUTTON_LABEL',
  SET_ACTION = 'SET_ACTION',
}
