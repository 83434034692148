import { Encounter } from '@/types/form/prior-auth/encounter'
import {
  EncounterDetails,
  isDiagnosticLab,
  isDrugTherapy,
  isDurableMedicalEquipment,
  isGeneticTesting,
  isHomeHealth,
  isHomeInfusion,
} from '@/types/form/prior-auth/encounter-details'
import { EncounterDetails as MappedEncounterDetails } from '@/types/api/prior-auth/encounter/details'
import { Encounter as MappedEncounter } from '@/types/api/prior-auth/encounter'
import { HomeHealth } from '@/types/api/prior-auth/encounter/details/home-health'
import { mapProcedure } from './procedure'
import { mapDiagnosticLab } from './diagnostic-lab'
import { mapDrugTherapy } from './drug-therapy'
import { mapDurableMedicalEquipment } from './durable-medical-equipment'
import { mapGeneticTesting } from './genetic-testing'
import { mapHomeInfusion } from './home-infusion'

export function mapEncounterDetails(details: EncounterDetails): MappedEncounterDetails {
  if (isDiagnosticLab(details)) {
    return mapDiagnosticLab(details)
  } else if (isDrugTherapy(details)) {
    return mapDrugTherapy(details)
  } else if (isGeneticTesting(details)) {
    return mapGeneticTesting(details)
  } else if (isHomeInfusion(details)) {
    return mapHomeInfusion(details)
  } else if (isDurableMedicalEquipment(details)) {
    return mapDurableMedicalEquipment(details)
  } else if (isHomeHealth(details)) {
    return { category: 'home_health' } as HomeHealth
  }
  return { category: details.category.toLowerCase() }
}
export function mapEncounter(encounter: Encounter | null): MappedEncounter | null {
  if (!encounter) return null

  return {
    service_type: encounter?.information?.serviceType || null,
    location_type: encounter?.information?.locationType || null,
    inpatient: encounter?.information?.inpatient ?? null,
    primary_procedure: mapProcedure(encounter?.procedures?.primaryProcedure || {}),
    additional_procedures:
      encounter?.procedures?.additionalProcedures?.map(procedure => mapProcedure(procedure)) || [],
    details: encounter?.details ? mapEncounterDetails(encounter.details) : null,
  }
}
