import { HomeInfusion } from '@/types/form/prior-auth/encounter-details'
import { Visits } from '@/types/form/prior-auth/encounter-details'
import { Pharmacy } from '@/types/form/prior-auth/encounter-details'
import { Visits as MappedVisits } from '@/types/api/prior-auth/encounter/details/visits'
import { Pharmacy as MappedPharmacy } from '@/types/api/prior-auth/encounter/details/pharmacy'
import { mapAddress } from './address'

import { HomeInfusion as MappedHomeInfusion } from '@/types/api/prior-auth/encounter/details/home-infusion'

function mapVisits(visits: Visits): MappedVisits {
  return {
    total_weeks: visits?.totalWeeks || null,
    weekly_frequency: visits?.weeklyFrequency || null,
  }
}

function mapPharmacy(pharmacy: Pharmacy): MappedPharmacy {
  return {
    name: pharmacy?.name || null,
    address: pharmacy?.address ? mapAddress(pharmacy?.address) : null,
    phone: pharmacy?.phone || null,
    phone_ext: pharmacy?.phoneExtension || null,
    fax: pharmacy?.fax || null,
  }
}

export function mapHomeInfusion(homeInfusion: HomeInfusion): MappedHomeInfusion {
  return {
    category: 'home_infusion',
    dosage: homeInfusion?.dosage || null,
    frequency: homeInfusion?.frequency || null,
    quantity: homeInfusion?.quantity || null,
    administration: homeInfusion?.administration || null,
    visits: homeInfusion?.visits ? mapVisits(homeInfusion?.visits) : null,
    pharmacy: homeInfusion?.pharmacy ? mapPharmacy(homeInfusion?.pharmacy) : null,
  }
}
