import { Attachment as APIAttachment } from '@/types/api/prior-auth/common/attachment'
import { Attachment as UIAttachment } from '@/types/form/prior-auth/attachment'

export function mapAttachment(attachment: UIAttachment): APIAttachment {
  return {
    name: attachment.name,
    type: attachment.type.toString(),
    attachment_id: attachment.attachmentId,
  }
}
