import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import root from './root'
import configModule from './config'
import wizardModule from './wizard'
import submissionModule from './submission'
import whiteLabelModule from './white-label'
import errorComponentModule from './error-component'
import metricsModule from './metrics'

export default createStore<any>({
  ...root,
  modules: {
    config: configModule,
    wizard: wizardModule,
    submission: submissionModule,
    whiteLabel: whiteLabelModule,
    errorComponent: errorComponentModule,
    metrics: metricsModule,
  },
  plugins: [
    createPersistedState({
      key: 'settings',
      paths: ['whiteLabel'],
      storage: window.sessionStorage,
    }),
  ],
})
