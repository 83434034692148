import { Facility } from '@/types/form/prior-auth/facility'
import { Facility as ResponseFacility } from '@/types/api/prior-auth/facilities'
import { FacilityInformation } from '@/types/form/prior-auth/facility-information'
import { AxiosResponse } from 'axios'
import { mapNetworkStatus } from '@/modules/helpers/mapping'
import { formatPhone } from '@/types/api/prior-auth/common/phone'

const format = (phone?: string | null): string | null => (phone ? formatPhone(phone) : null)

export function mapHttpResponseToFacility(response: AxiosResponse): Facility {
  const providerName = {
    organizationName: response.data.details.organization_name || null,
    type: 'organization',
  } as FacilityInformation

  return {
    information: {
      providerName,
      npi: response.data.npi || null,
      tin: response.data.details.tin || null,
      networkStatus: mapNetworkStatus(response.data.details.in_network),
    } as FacilityInformation,
    contact: {
      contactName: response.data.details.contact_name || null,
      email: response.data.details.email || null,
      fax: format(response.data.details.fax),
      phone: format(response.data.details.phone),
      phoneExtension: response.data.details.phone_ext || null,
    },
    address: {
      city: response.data.details.address.city || null,
      lineOne: response.data.details.address.line_one || null,
      lineTwo: response.data.details.address.line_two || null,
      lineThree: response.data.details.address.line_three || null,
      state: response.data.details.address.state || null,
      zipCode: response.data.details.address.zip_code || null,
    },
    sameAsProvider: false,
  }
}

export function mapCorrelationResponseToFacility(responseFacility: ResponseFacility): Facility {
  if (!responseFacility) {
    return { address: null, contact: null, information: null, sameAsProvider: false }
  }

  return {
    information: {
      organizationName: responseFacility?.name || null,
      npi: responseFacility.npi || null,
      tin: responseFacility.tin || null,
      networkStatus: mapNetworkStatus(responseFacility.in_network),
    } as FacilityInformation,
    contact: {
      contactName: responseFacility.contact_name || null,
      email: responseFacility.email || null,
      fax: format(responseFacility.fax),
      phone: format(responseFacility.phone),
      phoneExtension: responseFacility.phone_ext || null,
    },
    address: {
      city: responseFacility?.address?.city || null,
      lineOne: responseFacility?.address?.line_one || null,
      lineTwo: responseFacility?.address?.line_two || null,
      lineThree: responseFacility?.address?.line_three || null,
      state: responseFacility?.address?.state || null,
      zipCode: responseFacility?.address?.zip_code || null,
    },
    sameAsProvider: false,
  }
}
