import { Patient } from '@/types/form/prior-auth/patient'
import { Patient as MappedPatient } from '@/types/api/prior-auth/patient/index'
import { mapAddress } from '@/mappers/submission/address'
import { fromDate } from '@/types/api/prior-auth/common/date-f'

export function mapPatient(patient?: Patient | null): MappedPatient | null {
  if (!patient || !Object.values(patient).some(value => !!value)) {
    return null
  }

  return {
    patient_id: patient?.information?.patientId || null,
    first_name: patient?.information?.firstName || null,
    middle_name: patient?.information?.middleName || null,
    last_name: patient?.information?.lastName || null,
    date_of_birth: patient?.information?.dateOfBirth
      ? fromDate(new Date(patient?.information?.dateOfBirth))
      : null,
    gender: patient?.information?.gender || null,
    address: mapAddress(patient?.address),
    phone: patient?.information?.phone || null,
    height: patient?.information?.height || null,
    weight: patient?.information?.weight || null,
    ethnicity: patient?.information?.ethnicity || null,
    phone_ext: patient?.information?.phoneExtension || null,
    drug_allergies: patient?.drugAllergies || null,
    medical_record_number: patient?.information?.medicalRecordNumber || null,
  }
}
