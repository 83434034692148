export function isAppDomain(hostname: string): boolean {
  return hostname.indexOf('.app') !== -1
}

export function getCommonDomain(): string {
  const hostname = document.location.hostname
  return isAppDomain(hostname) ? 'myndview.app' : hostname.split('.').slice(-3).join('.')
}

/**
 * Returns all cookies regardless of value
 */
export function getAllCookies(): Record<string, string> {
  return Object.fromEntries(document.cookie.split('; ').map(cookie => cookie.split('=')))
}

/**
 * Returns all cookies with a non-null value
 */
export function getNonNullCookies(): Record<string, string> {
  return Object.fromEntries(
    document.cookie
      .split('; ')
      .map(cookie => cookie.split('='))
      .filter(([_, v]) => v !== 'null')
  )
}

/**
 * Returns the token
 */
export function getTokenFromCookies(): string {
  return (
    Object.fromEntries(
      document.cookie
        .split('; ')
        .map(cookie => cookie.split('='))
        .filter(([_, v]) => v !== 'null')
    )?.token || ''
  )
}

/**
 * Expire all the cookies associated with the domain
 */
export function expireAllCookies(): void {
  const commonDomain = getCommonDomain()
  const cookies = getAllCookies()
  Object.entries(cookies).forEach(([k, v]) => {
    document.cookie = `${k}=${v}; domain=${commonDomain}; path=/; secure; max-age=-1;`
    document.cookie = `${k}=${v}; domain=${commonDomain}; path=/wizard; secure; max-age=-1;`
  })
}

export function setCookies(cookies: Record<string, string | null>) {
  const commonDomain = getCommonDomain()
  Object.entries(cookies).forEach(([k, v]) => {
    document.cookie = `${k}=${v}; domain=${commonDomain}; secure;`
  })
}

export function setCookie(cookie: Record<string, string | null>) {
  const [k, v] = Object.entries(cookie)[0]
  const commonDomain = getCommonDomain()
  document.cookie = `${k}=${v}; domain=${commonDomain}; secure;'
  }`
}

/**
 * Expire the cookies specified in the list that are associated with the domain
 * @param list
 */
export function expireCookiesByList(list: string[]) {
  const cookies = getAllCookies()
  const commonDomain = getCommonDomain()
  list.forEach(name => {
    if (cookies[name]) {
      document.cookie = `${name}=${cookies[name]}; domain=${commonDomain}; secure; max-age=-1;`
    }
  })
}
