import { DurableMedicalEquipment } from '@/types/form/prior-auth/encounter-details'
import { DurableMedicalEquipment as MappedDurableMedicalEquipment } from '@/types/api/prior-auth/encounter/details/durable-medical-equipment'

export function mapDurableMedicalEquipment(
  dme: DurableMedicalEquipment
): MappedDurableMedicalEquipment {
  return {
    category: 'durable_medical_equipment',
    duration: dme?.duration || null,
    cost: dme?.cost || null,
    payment: dme?.payment || null,
    rental_start_date:
      dme?.rentalStartDate != null
        ? dme?.rentalStartDate?.toISOString().replace('Z', '+00:00')
        : null,
    rental_end_date:
      dme?.rentalEndDate != null ? dme?.rentalEndDate?.toISOString().replace('Z', '+00:00') : null,
  }
}
