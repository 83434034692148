import { DrugTherapy } from '@/types/form/prior-auth/encounter-details'
import { DrugTherapy as MappedDrugTherapy } from '@/types/api/prior-auth/encounter/details/drug-therapy'

export function mapDrugTherapy(drugTherapy: DrugTherapy): MappedDrugTherapy {
  return {
    category: 'drug_therapy',
    dosage: drugTherapy.dosage || null,
    strength: drugTherapy.strength || null,
    frequency: drugTherapy.frequency || null,
    quantity: drugTherapy.quantity || null,
    refills: drugTherapy.refills || null,
    administration: drugTherapy.administration || null,
    duration: drugTherapy.duration || null,
    dea_number: drugTherapy.deaNumber || null,
    continuation_date:
      drugTherapy.continuationDate != null
        ? drugTherapy.continuationDate.toISOString().replace('Z', '+00:00')
        : null,
  }
}
