import * as I from 'io-ts'

export interface DateFBrand {
  readonly DateF: unique symbol
}

export type DateF = I.Branded<string, DateFBrand>

const format = /^\d{4}-\d{1,2}-\d{1,2}$/

/**
 * ISO 8601 date (`%tY-%tm-%td`) formatted string.
 */
export const DateF = I.brand(I.string, (d): d is DateF => I.string.is(d) && format.test(d), 'DateF')

/**
 * Converts a [[Date]] into a [[DateF]].
 */
export const fromDate = (d: Date): DateF => {
  return d?.toISOString().substring(0, 10) as DateF
}
