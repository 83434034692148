import { State } from './state'
import { ErrorMutationTypes, AlignmentOptions } from '@/types/error-component/error-component'
import { AnyFunction } from '@/types/function-type'

export const mutations = {
  [ErrorMutationTypes.SET_BANNER_URL](state: State, url: string) {
    state.errorComponent.bannerUrl = url
  },
  [ErrorMutationTypes.SET_BANNER_TITLE](state: State, title: string) {
    state.errorComponent.bannerTitle = title
  },
  [ErrorMutationTypes.SET_MESSAGE_TITLE](state: State, title: string) {
    state.errorComponent.messageTitle = title
  },
  [ErrorMutationTypes.SET_MESSAGE_LINE_ONE](state: State, lineOne: string) {
    state.errorComponent.messageLineOne = lineOne
  },
  [ErrorMutationTypes.SET_MESSAGE_LINE_TWO](state: State, lineTwo: string) {
    state.errorComponent.messageLineTwo = lineTwo
  },
  [ErrorMutationTypes.SET_MESSAGE_LINE_THREE](state: State, lineThree: string) {
    state.errorComponent.messageLineThree = lineThree
  },
  [ErrorMutationTypes.SET_TITLE_ALIGN](state: State, align: AlignmentOptions) {
    state.errorComponent.titleAlign = align
  },
  [ErrorMutationTypes.SET_MESSAGE_ALIGN](state: State, align: AlignmentOptions) {
    state.errorComponent.messageAlign = align
  },
  [ErrorMutationTypes.SET_BUTTON_LABEL](state: State, label: string) {
    state.errorComponent.buttonLabel = label
  },
  [ErrorMutationTypes.SET_ACTION](state: State, action: null | AnyFunction) {
    state.errorComponent.action = action
  },
}
