import { initializeApp } from 'firebase/app'
import {
  browserLocalPersistence,
  browserSessionPersistence,
  browserPopupRedirectResolver,
  initializeAuth,
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  prodErrorMap,
  Auth,
  GoogleAuthProvider,
  OAuthProvider,
  SAMLAuthProvider,
} from 'firebase/auth'
import { MyndConfiguration, envProjectID } from '@/types/config/environment'

function signInWithGoogle(auth: Auth) {
  const provider = new GoogleAuthProvider()
  provider.addScope('email')
  provider.addScope('openid')
  provider.addScope('profile')
  return signInWithPopup(auth, provider)
}

function signInWithOAuth(auth: Auth, id: string) {
  if (!id.startsWith('oidc.')) {
    id = 'oidc.' + id
  }
  const provider = new OAuthProvider(id)
  provider.addScope('email')
  provider.addScope('openid')
  provider.addScope('profile')
  return signInWithPopup(auth, provider)
}

function signInWithSAML(auth: Auth, id: string) {
  if (!id.startsWith('saml.')) {
    id = 'saml.' + id
  }
  const provider = new SAMLAuthProvider(id)
  return signInWithPopup(auth, provider)
}

function tenantCheck(hostname: string, config: MyndConfiguration) {
  const tenant = config.tenantConfiguration.tenants[hostname]
  if (!tenant) {
    throw 'no matching tenant for hostname'
  }
  return tenant
}

/**
 * Creates firebase app and initializes using config
 * @param hostname
 * @param config
 */
export function initializeFirebaseApp(hostname: string, config: MyndConfiguration): void {
  const tenant = tenantCheck(hostname, config)

  const project = envProjectID(config.environment)
  const app = initializeApp({
    apiKey: config.tenantConfiguration.apiKey,
    appId: config.tenantConfiguration.appId,
    authDomain: `${project}.firebaseapp.com`,
    projectId: project,
  })

  const auth = initializeAuth(app, {
    persistence: [browserLocalPersistence, browserSessionPersistence],
    popupRedirectResolver: browserPopupRedirectResolver,
    errorMap: prodErrorMap,
  })
  auth.useDeviceLanguage()
  auth.tenantId = tenant.externalId
}

/**
 * Returns signIn function based on provider type
 * @param hostname
 * @param config
 */
export function useFirebaseAuth(hostname: string, config: MyndConfiguration) {
  const tenant = tenantCheck(hostname, config)

  return {
    providerName: tenant.name,
    signInOptions: tenant.providers,
    signIn: function (
      providerType: string,
      providerId?: string,
      email?: string,
      password?: string
    ) {
      const auth = getAuth()
      switch (providerType) {
        case 'email':
          if (email && password) {
            return signInWithEmailAndPassword(auth, email, password)
          } else {
            throw Error('empty value failed auth')
          }
        case 'google':
          return signInWithGoogle(auth)

        case 'saml':
          if (providerId) {
            return signInWithSAML(auth, providerId)
          } else {
            throw Error('empty value failed auth')
          }
        case 'oidc':
          if (providerId) {
            return signInWithOAuth(auth, providerId)
          } else {
            throw Error('empty value failed auth')
          }
        default:
          throw `invalid provider type '${providerType}'`
      }
    },
  }
}
