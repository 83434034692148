import { Commit } from 'vuex'
import { getConfiguration as getConfig } from '@/modules/config/hook'
import { defaultMessages } from '@/modules/form-population/mapper'
import { ErrorState } from '@/types/error-component/error-component'
import { getMyndviewUrl } from '@/modules/helpers/http'
import { expireAllCookies } from '@/modules/cookies/cookies'

const getConfiguration = async ({ commit, dispatch }: { commit: Commit; dispatch: any }) => {
  try {
    const response = await getConfig()
    commit('setConfiguration', response?.config)
    commit('setCSRF', response?.csrf, { root: true })
  } catch (error: any) {
    const status = error?.response?.data?.status || error?.status || 0
    const defaultMessage = defaultMessages(status)

    const backToMyndviewButton = () => {
      const url = getMyndviewUrl(null, null)
      expireAllCookies()
      window.onbeforeunload = null
      window.location.assign(url)
    }

    const errorSettings: Partial<ErrorState> = {
      messageLineOne: defaultMessage,
      buttonLabel: 'Back to Myndview',
      action: backToMyndviewButton,
    }

    await dispatch('errorComponent/setErrorFromTemplate', errorSettings, { root: true })
    commit('wizard/setErrorReported', true, { root: true })
  }
}

export const actions = {
  getConfiguration,
}
