import * as WizardModels from '../../../models/wizard/fe'
import { MAX_DEPTH } from '@/modules/wizard/additional-information'

export const mapAdditionalInformationDataModel = (
  properties: WizardModels.AdditionalInformationProperty[],
  depth?: number
): any => {
  const currentDepth = depth ?? 0
  if (currentDepth > MAX_DEPTH) return null

  const dataModel: any = {}
  for (const property of properties) {
    if (WizardModels.isAdditionalInformationObjectProperty(property)) {
      dataModel[property.name] = mapAdditionalInformationDataModel(
        property.properties,
        currentDepth + 1
      )
    } else if (WizardModels.isAdditionalInformationArrayProperty(property)) {
      if (property.value.length > 0) {
        dataModel[property.name] = property.value
      }
    } else if (
      WizardModels.isAdditionalInformationStringProperty(property) ||
      WizardModels.isAdditionalInformationNumberProperty(property) ||
      WizardModels.isAdditionalInformationIntegerProperty(property) ||
      WizardModels.isAdditionalInformationBooleanProperty(property) ||
      WizardModels.isAdditionalInformationDateProperty(property) ||
      WizardModels.isAdditionalInformationEnumProperty(property)
    ) {
      if (property.value !== null && property.value !== '') {
        dataModel[property.name] = property.value
      }
    }
  }

  return dataModel
}

export function mapAdditionalInformationData(
  additionalInformation: WizardModels.AdditionalInformationProperty[]
) {
  return additionalInformation ? mapAdditionalInformationDataModel(additionalInformation) : null
}
