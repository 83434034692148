import * as WizardModels from '../../../models/wizard/fe'

const MAX_DEPTH = 5

export const mapValuesToAdditionalProperties = (
  additionalProperties: WizardModels.AdditionalInformationProperty[],
  values: any,
  depth = 0
): WizardModels.AdditionalInformationProperty[] => {
  if (!values) {
    return additionalProperties
  }
  const updatePropertyValueIfExists = (
    property: WizardModels.AdditionalInformationProperty,
    values: any
  ): WizardModels.AdditionalInformationProperty => {
    let newProperty: WizardModels.AdditionalInformationProperty = property
    if (Object.keys(values).includes(property.name)) {
      const value = values[property.name]
      newProperty = { ...property, value } as WizardModels.AdditionalInformationProperty
    }
    return newProperty
  }

  const newProperties: WizardModels.AdditionalInformationProperty[] = []
  for (const property of additionalProperties) {
    if (!WizardModels.isAdditionalInformationObjectProperty(property)) {
      newProperties.push(updatePropertyValueIfExists(property, values))
    } else if (WizardModels.isAdditionalInformationObjectProperty(property) && depth <= MAX_DEPTH) {
      const properties = mapValuesToAdditionalProperties(
        property.properties,
        values?.[property.name] || [],
        depth + 1
      )
      const newProperty: WizardModels.AdditionalInformationProperty = { ...property, properties }

      newProperties.push(newProperty)
    }
  }

  return newProperties
}
