import { useSubmissionApi } from '@/modules/submission/http'
import { track } from '@/modules/logrocket/logrocket'

/**
 * Used for white labeling the application styles based on domain and tenant
 * @param store
 */
export async function useWhiteLabeling(store: any) {
  try {
    const { getTenantConfigSettings } = useSubmissionApi(
      store.state.wizard.session.token,
      store.state.wizard.clientId,
      store.state.csrf || ''
    )

    const tenantSettingsResponse = await getTenantConfigSettings().catch(_ => {
      track('PATenantSettingsError', { origin: window.location.origin })
      return { data: null }
    })

    store.commit('whiteLabel/setWhiteLabelConfig', tenantSettingsResponse.data)
    return tenantSettingsResponse.data
  } catch (error: any) {
    track('PATenantSettingsError', { origin: window.location.origin })
    return null
  }
}
