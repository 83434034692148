import { ErrorState } from '@/types/error-component/error-component'
import { getMyndviewUrl } from '@/modules/helpers/http'
import { expireAllCookies } from '@/modules/cookies/cookies'

const backToMyndview = () => {
  const url = getMyndviewUrl(null, null)
  expireAllCookies()
  window.onbeforeunload = null
  window.location.assign(url)
}

export const state = {
  errorComponent: {
    bannerUrl: '',
    bannerTitle: '',
    messageTitle: 'Uh oh!',
    messageLineOne: 'An unknown error occurred',
    messageLineTwo: '',
    messageLineThree: '',
    titleAlign: 'center',
    messageAlign: 'center',
    buttonLabel: 'Return to Myndview',
    action: backToMyndview,
  } as ErrorState,
}

export type State = typeof state
