import { RouteRecordRaw } from 'vue-router'
import { SectionStatus, WizardSection } from '@/modules/wizard/types'
import store from '../store'

export const wizardSubRoutes: Array<RouteRecordRaw> = [
  {
    path: 'details',
    name: 'Details',
    component: () => import('@/components/wizard/sections/Details.vue'),
    beforeEnter: (_to, _from, next) => {
      if (store.getters['wizard/isErrorReported']) {
        return
      } else {
        next()
      }
    },
    meta: {
      requiresAuth: true,
      section: WizardSection.DETAILS,
      value: SectionStatus,
      selected: true,
      valid: true,
      dirty: false,
      errorCount: 0,
    },
  },
  {
    path: 'patient',
    name: 'Patient',
    component: () => import('@/components/wizard/sections/Patient.vue'),
    meta: {
      requiresAuth: true,
      section: WizardSection.PATIENT,
      value: SectionStatus,
      selected: false,
      valid: true,
      dirty: false,
      errorCount: -1,
    },
  },
  {
    path: 'ordering-provider',
    name: 'Ordering Provider',
    component: () => import('@/components/wizard/sections/OrderingProvider.vue'),
    meta: {
      requiresAuth: true,
      section: WizardSection.ORDERING_PROVIDER,
      value: SectionStatus,
      selected: false,
      valid: true,
      dirty: false,
      errorCount: -1,
    },
  },
  {
    path: 'servicing-provider',
    name: 'Servicing Provider',
    component: () => import('@/components/wizard/sections/ServicingProvider.vue'),
    meta: {
      requiresAuth: true,
      section: WizardSection.SERVICING_PROVIDER,
      value: SectionStatus,
      selected: false,
      valid: true,
      dirty: false,
      errorCount: -1,
    },
  },
  {
    path: 'pcp-provider',
    name: 'Primary Care Provider',
    component: () => import('@/components/wizard/sections/PCPProvider.vue'),
    meta: {
      requiresAuth: true,
      section: WizardSection.PCP_PROVIDER,
      value: SectionStatus,
      selected: false,
      valid: true,
      dirty: false,
      errorCount: -1,
    },
  },
  {
    path: 'referring-provider',
    name: 'Referring Provider',
    component: () => import('@/components/wizard/sections/ReferringProvider.vue'),
    meta: {
      requiresAuth: true,
      section: WizardSection.REFERRING_PROVIDER,
      value: SectionStatus,
      selected: false,
      valid: true,
      dirty: false,
      errorCount: -1,
    },
  },
  {
    path: 'ordering-facility',
    name: 'Ordering Facility',
    component: () => import('@/components/wizard/sections/OrderingFacility.vue'),
    meta: {
      requiresAuth: true,
      section: WizardSection.ORDERING_FACILITY,
      value: SectionStatus,
      selected: false,
      valid: true,
      dirty: false,
      errorCount: -1,
    },
  },
  {
    path: 'servicing-facility',
    name: 'Servicing Facility',
    component: () => import('@/components/wizard/sections/ServicingFacility.vue'),
    meta: {
      requiresAuth: true,
      section: WizardSection.SERVICING_FACILITY,
      value: SectionStatus,
      selected: false,
      valid: true,
      dirty: false,
      errorCount: -1,
    },
  },
  {
    path: 'encounter',
    name: 'Encounter',
    component: () => import('@/components/wizard/sections/Encounter.vue'),
    meta: {
      requiresAuth: true,
      section: WizardSection.ENCOUNTER,
      value: SectionStatus,
      selected: false,
      valid: true,
      dirty: false,
      errorCount: -1,
    },
  },
  {
    path: 'additional-information',
    name: 'Additional Information',
    component: () => import('@/components/wizard/sections/AdditionalInformation.vue'),
    meta: {
      requiresAuth: true,
      section: WizardSection.ADDITIONAL_INFORMATION,
      value: SectionStatus,
      selected: false,
      valid: true,
      dirty: false,
      errorCount: -1,
    },
  },
  {
    path: 'attachments',
    name: 'Attachments',
    component: () => import('@/components/wizard/sections/Attachments.vue'),
    meta: {
      requiresAuth: true,
      section: WizardSection.ATTACHMENTS,
      value: SectionStatus,
      selected: false,
      valid: true,
      dirty: false,
      errorCount: -1,
    },
  },
  {
    path: 'review',
    name: 'Review',
    component: () => import('@/components/wizard/sections/Review.vue'),
    meta: {
      requiresAuth: true,
      section: WizardSection.REVIEW,
      value: SectionStatus,
      selected: false,
      valid: true,
      dirty: false,
      errorCount: 0,
    },
  },
]
