export enum AttachmentType {
  SIGNED_ORDER = 'SIGNED_ORDER',
  HISTORY_AND_PHYSICAL = 'HISTORY_AND_PHYSICAL',
  MISC_ATTACHMENT = 'MISC_ATTACHMENT',
}

export interface Attachment {
  attachmentId: string
  name: string
  type: AttachmentType
}
