import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from '@/router'
import { applyDefaultFontFamily } from '@myndshft/fonts'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import '@myndshft/web-components/lib/notification-banner/index'

import {
  faTrashAlt,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faTimes,
  faInfoCircle,
  faEnvelope,
  faLock,
  faSignOutAlt,
  faSave,
  faCopy,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faTrashAlt,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faTimes,
  faInfoCircle,
  faEnvelope,
  faLock,
  faSignOutAlt,
  faSave,
  faCopy
)

createApp(App).use(store).use(router).component('fa-icon', FontAwesomeIcon).mount('#app')

applyDefaultFontFamily()
