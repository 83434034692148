import { Insurance as InsuranceInTransaction } from '@/types/api/prior-auth/insurance'
import { Insurance } from '@/types/form/prior-auth/insurance'
import { Id } from '@/types/form/prior-auth/id'
import { v4 as uuid } from 'uuid'

export function mapInsurance(insurance: InsuranceInTransaction): Insurance & Id {
  return {
    id: uuid(),
    payerUniqueId: insurance.unique_id,
    planUniqueId: insurance.plan?.unique_id || null,
    planState: insurance.plan?.state || null,
    memberId: insurance.member_id || null,
    groupNumber: insurance.group_number,
    subscriberId: insurance.subscriber_id,
    subscriberName: insurance.subscriber_name,
    primary: insurance.primary,
  } as Insurance & Id
}
