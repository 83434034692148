import { Procedure } from '@/types/form/prior-auth/procedures'
import { Diagnosis } from '@/types/form/prior-auth/procedures'
import { Diagnosis as MappedDiagnosis } from '@/types/api/prior-auth/encounter/diagnosis'
import { Procedure as MappedProcedure } from '@/types/api/prior-auth/encounter/procedure'

function mapDiagnosis(diagnosis: Diagnosis | null): MappedDiagnosis {
  if (!diagnosis) {
    return { code: null, description: null }
  }

  return {
    code: diagnosis?.code || null,
    description: diagnosis?.description || null,
  }
}

export function mapProcedure(procedure: Procedure): MappedProcedure {
  return {
    start_date: procedure?.startDate?.toISOString().replace('Z', '+00:00') || null,
    end_date: procedure?.endDate?.toISOString().replace('Z', '+00:00') || null,
    code: procedure?.procedureCode || null,
    code_description: procedure?.codeDescription || null,
    code_modifier: procedure?.codeModifier || null,
    units: procedure?.units || null,
    primary_diagnosis: mapDiagnosis(procedure?.diagnoses?.primaryDiagnosis || null),
    additional_diagnoses:
      procedure?.diagnoses?.additionalDiagnoses?.map(diagnosis => mapDiagnosis(diagnosis)) || [],
  }
}
