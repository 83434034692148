import axios from 'axios'
import { getBFFRoute } from '@/modules/helpers/http'

const fetchFeatureFlags = async (
  apiServer: string,
  clientId?: string,
  email?: string,
  csrf?: string
) => {
  const response = await axios.post(
    `${apiServer}/api/features`,
    {
      identifiers: clientId || '',
      email: email || '',
    },
    {
      headers: {
        'x-csrf-token': csrf || '',
      },
      responseType: 'json',
    }
  )
  return response.data
}
export const getFeatureFlags = (clientId?: string, email?: string, csrf?: string) =>
  fetchFeatureFlags(getBFFRoute(), clientId, email, csrf)
