import LogRocket from 'logrocket'
import WhiteListBody from '@myndshft/whitelist'
import { IConfig } from '@myndshft/whitelist/lib/types'

export function initLogRocket(env: string, version: string) {
  const canInitialize = env !== 'development' && env !== 'local'
  if (canInitialize) {
    const config: IConfig = {
      request: [],
      response: [],
    }
    const { requestSanitizer, responseSanitizer } = WhiteListBody.init(config)
    LogRocket.init('ta8rj2/pa-submit', {
      network: {
        requestSanitizer,
        responseSanitizer,
      },
      dom: {
        inputSanitizer: true,
      },
      release: version,
    })
  }
}

export function logRocketIdentifyUser(email: string | null, name?: string | null) {
  if (email) {
    LogRocket.identify(email, {
      name: name || email,
      email: email,
    })
  }
}

export function track(eventName: string, eventDetails: any = {}): void {
  const event = { ...eventDetails }
  LogRocket.track(eventName, event)
}
