import { State } from './state'

const setProvidersShownCount = (state: State, value: number): void => {
  state.providersShownCount = value
}
const setPFacilitiesShownCount = (state: State, value: number): void => {
  state.facilitiesShownCount = value
}

export const mutations = {
  setProvidersShownCount,
  setPFacilitiesShownCount,
}
