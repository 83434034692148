import { Address } from './address'
import * as I from 'io-ts'

export interface Visits {
  totalWeeks?: number | null
  weeklyFrequency?: number | null
}

export interface Pharmacy {
  name?: string | null
  address?: Address | null
  phone?: string | null
  phoneExtension?: string | null
  fax?: string | null
}

export const Payment = I.keyof({
  purchase: null,
  rental: null,
})
export type Payment = I.TypeOf<typeof Payment>

export const Administration = I.keyof({
  injection: null,
  intravenous: null,
  oral: null,
  topical: null,
  other: null,
})
export type Administration = I.TypeOf<typeof Administration>

export interface DrugTherapy {
  category: 'DRUG_THERAPY'
  dosage?: string | null
  strength?: string | null
  frequency?: string | null
  quantity?: string | null
  refills?: number | null
  administration?: string | null
  duration?: string | null
  deaNumber?: string | null
  continuationDate?: Date | null
}
export const isDrugTherapy = (details: any): details is DrugTherapy =>
  details.category === 'DRUG_THERAPY'

export interface HomeInfusion {
  category: 'HOME_INFUSION'
  administration?: string | null
  dosage?: string | null
  frequency?: string | null
  quantity?: string | null
  visits?: Visits | null
  pharmacy?: Pharmacy | null
}

export const isHomeInfusion = (details: any): details is HomeInfusion =>
  details.category === 'HOME_INFUSION'

export interface DurableMedicalEquipment {
  category: 'DURABLE_MEDICAL_EQUIPMENT'
  duration?: string | null
  cost?: number | null
  payment?: string | null
  rentalStartDate?: Date | null
  rentalEndDate?: Date | null
}

export const isDurableMedicalEquipment = (details: any): details is DurableMedicalEquipment =>
  details.category === 'DURABLE_MEDICAL_EQUIPMENT'

export interface DiagnosticLab {
  category: 'DIAGNOSTIC_LAB'
  specimenCollected?: boolean | null
  specimenCollectedDate?: Date | null
}

export const isDiagnosticLab = (details: any): details is DiagnosticLab =>
  details.category === 'DIAGNOSTIC_LAB'

export interface GeneticTesting {
  category: 'GENETIC_TESTING'
  counselorName?: string | null
  counselingDate?: Date | null
  specimenCollected?: boolean | null
  specimenCollectedDate?: Date | null
}

export const isGeneticTesting = (details: any): details is GeneticTesting =>
  details.category === 'GENETIC_TESTING'

export interface HomeHealth {
  category: 'HOME_HEALTH'
}

export const isHomeHealth = (details: any): details is HomeHealth =>
  details.category === 'HOME_HEALTH'

export interface OtherCategory {
  category: string
}

export type EncounterDetails =
  | DrugTherapy
  | HomeInfusion
  | DurableMedicalEquipment
  | DiagnosticLab
  | HomeHealth
  | GeneticTesting
  | OtherCategory
