<template>
  <div v-if="hasConfig || errorReported">
    <router-view></router-view>
  </div>
</template>

<script lang="ts">
import {
  computed,
  onBeforeMount,
  defineComponent,
  onMounted,
  onUnmounted,
  provide,
  watch,
  ref,
} from 'vue'
import { useStore } from 'vuex'
import { useForm, validate as forceValidate } from 'vee-validate'
import { Config as WhitelabelConfig } from '@myndshft/whitelabeling/lib/index.js'
import { getApps } from 'firebase/app'

import { useWhiteLabeling } from '@/modules/helpers/whitelabeling'
import { initializeFirebaseApp } from '@/modules/auth/firebase'
import { InactivityTracker } from '@/modules/inactivity/inactivity'

import { MyndConfiguration } from '@/types/config/environment'
import { Font, FontLinks } from '@myndshft/whitelabeling/lib/index.js'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  name: 'App',
  setup() {
    const store = useStore()
    const { errors, validate, values: formValues, setFieldError } = useForm()
    const route = useRoute()
    const router = useRouter()
    const errorReported = computed(() => store.getters['wizard/isErrorReported'])

    provide('errors', errors)
    provide('validate', validate)
    provide('formValues', formValues)
    provide('forceValidate', forceValidate)
    provide('setFieldError', setFieldError)

    const showLoading = computed(() => store.getters['app/getShowLoading'])

    const config = computed(() => store.getters['config/getConfig'])
    const flags = computed(() => store.getters['wizard/getFeatureFlags'])
    const reVerify = computed(() => store.getters['wizard/getReVerify'])
    const hasConfig = ref(false)
    const authorized = computed(() => store.getters['wizard/isAuthorized'])
    const isLoadingTransaction = computed(() => store.getters['wizard/isLoadingTransaction'])
    const whitelabelSettings = ref<null | WhitelabelConfig>(null)
    const hasWhiteLabelSettings = ref(false)

    const setupFonts = (font: Font): void => {
      if (
        ['submit', 'pa-submit', 'localhost'].includes(
          window.location.hostname.split('.')?.[0] || ''
        )
      ) {
        document.body.style.fontFamily = `'Effra', sans-serif`
      } else {
        font.links.forEach((config: FontLinks) => {
          const link = document.createElement('link')
          link.rel = config.rel
          link.href = config.href
          if (config.crossorigin) {
            link.crossOrigin = config.crossorigin
          }
          document.head.appendChild(link)
        })
        document.body.style.fontFamily = `'${font.name}', 'Effra', sans-serif`
      }
    }

    const applyWhiteLabelSettings = () => {
      const whiteLabelConfig = store.getters['whiteLabel/whitelabelConfig'] as WhitelabelConfig
      if (whiteLabelConfig) {
        whitelabelSettings.value = whiteLabelConfig
        const root = document.documentElement
        root?.style.setProperty('--primary-color', whiteLabelConfig.colors.primary)
        root?.style.setProperty('--secondary-color', whiteLabelConfig.colors.secondary)
        root?.style.setProperty('--tertiary-color', whiteLabelConfig.colors.tertiary)

        const favicon = document.getElementById('favicon')
        favicon?.setAttribute('href', whiteLabelConfig.browserTab.imageUrl)

        document.title = whiteLabelConfig.browserTab.title

        setupFonts(whiteLabelConfig.font)
      }

      hasWhiteLabelSettings.value = true
    }

    onBeforeMount(async () => {
      await store.dispatch('config/getConfiguration')
      await store.dispatch('wizard/getFeatureFlags')
      await useWhiteLabeling(store)
      applyWhiteLabelSettings()
    })

    onMounted(() => {
      window.onbeforeunload = function () {
        return {}
      }
    })

    onUnmounted(() => {
      InactivityTracker.stopInactivityMonitoring()
    })

    watch(authorized, hasAuth => {
      if (hasAuth) {
        store.commit('wizard/setClientId', route.query.clientId?.toString() || null)
        store.commit('wizard/setCorrelationId', route.query.correlationId?.toString() || null)
        store.dispatch('wizard/getFeatureFlags')
        store.dispatch('wizard/getProcedureCodes')
        if (hasConfig.value) {
          store.commit('wizard/setApiServer', store.state?.config?.config?.apiServer || '')

          if (
            store.state?.config?.config?.inactivityLogoutAfterMilliseconds &&
            store.state?.config?.config?.inactivityWarningAfterMilliseconds
          ) {
            InactivityTracker.startInactivityMonitoring(
              store.state.config.config.inactivityWarningAfterMilliseconds,
              store.state.config.config.inactivityLogoutAfterMilliseconds,
              store
            )
          }
        }
      } else {
        InactivityTracker.stopInactivityMonitoring()
      }
    })

    watch(reVerify, needsVerification => {
      if (needsVerification && flags?.value?.has('isEnabledToUseTheSessionCookieToFetchTheToken')) {
        store.dispatch('wizard/verifySession')
      }
    })

    watch(config, (config: MyndConfiguration) => {
      store.commit('wizard/setApiServer', config?.apiServer || '')
      if (config && config.tenantConfiguration) {
        const firebaseApps = getApps()
        if (!firebaseApps.length) {
          initializeFirebaseApp(window.location.hostname, config)
        }
      }
      hasConfig.value = true
    })

    watch(errorReported, report => {
      if (report) {
        router.push({ name: 'Error' })
      }
    })

    return {
      errorReported,
      hasConfig,
      showLoading,
      isLoadingTransaction,
      whitelabelSettings,
      hasWhiteLabelSettings,
    }
  },
})
</script>

<style lang="scss">
@import '@/styles/input.scss';
@import '@/styles/reusable.scss';
@import '@myndshft/color-palette/src/colors.scss';
@import '@myndshft/styles/src/components/mixins.scss';

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $myndshft-light-black;
}

.component-container {
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

:root {
  --primary-color: #{$myndshft-blue};
  --secondary-color: #{$myndshft-notification-accent-blue};
  --tertiary-color: #{$myndshft-notification-accent-orange};
}
</style>
