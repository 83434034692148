<template>
  <div class="login-container">
    <div class="login-inner-container ms-card">
      <div id="login-options">
        <div>
          <img
            :src="settings?.imageUrl"
            :alt="`${settings?.title} Logo`"
            class="logo"
            @load="onImageFinished"
            @error="onImageFinished"
          />
          <form @submit.prevent="onSubmit" class="form">
            <p class="ms-label-text">Email</p>
            <div class="field">
              <input
                type="email"
                placeholder="example@website.com"
                v-model="email"
                autocomplete="off"
                class="pad-left wl-ms-input"
              />
              <span class="ms-input-icon">
                <fa-icon icon="envelope"></fa-icon>
              </span>
            </div>
            <p class="ms-label-text">Password</p>
            <div class="field">
              <input
                type="password"
                placeholder="min. 8 characters"
                v-model="password"
                autocomplete="off"
                class="pad-left wl-ms-input"
              />
              <span class="ms-input-icon">
                <fa-icon icon="lock"></fa-icon>
              </span>
            </div>
            <a class="wl-link align-right forgot-password" :href="passwordResetUrl" target="_blank"
              >Forgot password?</a
            >
            <button type="submit" class="login-button margin-tb" :disabled="disabled">Login</button>
            <p class="align-center ms-label-text">
              Not registered yet?
              <a class="wl-link" :href="createAccountUrl" target="_blank">Create an Account</a>
            </p>
          </form>
          <div
            v-for="provider in signInOptions"
            class="login-option-button"
            :class="provider.type"
            :key="provider.type + provider.id"
            @click="signIn(provider.type, provider.id)"
          >
            <input
              type="image"
              class="google-sign-in"
              v-if="provider.type === 'google'"
              src="/assets/btn_google_signin_light_normal_web@2x.png"
            />
            <button
              v-if="provider.type !== 'google' && provider.type !== 'email'"
              class="login-button"
            >
              Sign in with {{ provider.name }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <page-footer :show-copyright="false"></page-footer>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { Config as WhitelabelConfig } from '@myndshft/whitelabeling/lib/index.js'

import { useFirebaseAuth } from '@/modules/auth/firebase'
import { logRocketIdentifyUser } from '@/modules/logrocket/logrocket'
import { MyndConfiguration } from '@/types/config/environment'

import PageFooter from '@/components/shared/PageFooter.vue'

const passwordReset = '/forgot-password?source=pas'
const createAccount = '/register?source=pas'

export default defineComponent({
  name: 'Auth',
  components: {
    PageFooter,
  },
  emits: ['received-token', 'image-finished'],
  setup(_, { emit }) {
    const store = useStore()
    const config = computed(() => store.state.config.config as MyndConfiguration)
    const settings = computed<{ title?: string; imageUrl?: string }>(() => {
      const whiteLabelConfig = store.getters['whiteLabel/whitelabelConfig'] as WhitelabelConfig
      return {
        title: whiteLabelConfig.banner?.title,
        imageUrl: whiteLabelConfig.banner?.imageUrl,
      }
    })
    let myndviewUrl = config.value.myndviewUrl
    const currentHref = document.location.origin
    if (currentHref.includes('myndview.app')) {
      myndviewUrl = currentHref.replace('submit.', '')
    } else if (currentHref.includes('pa-submit')) {
      myndviewUrl = currentHref.replace('pa-submit', 'myndview')
    }
    const passwordResetUrl = myndviewUrl + passwordReset
    const createAccountUrl = myndviewUrl + createAccount

    if (!config.value || !config.value.tenantConfiguration) {
      return {}
    }

    const auth = ref(useFirebaseAuth(window.location.hostname, config.value))
    let token: string
    const email = ref('')
    const password = ref('')
    const disabled = ref(true)

    const onSubmit = async () => {
      const credentials = await auth.value.signIn('email', undefined, email.value, password.value)
      const token = await credentials.user.getIdToken()
      const credentialEmail = credentials?.user?.email || null
      logRocketIdentifyUser(credentialEmail, credentials.user.displayName)
      store.commit('wizard/setUserEmail', credentialEmail)
      emit('received-token', token)
    }

    const signIn = async (providerType: string, providerId?: string) => {
      const credentials = await auth.value.signIn(providerType, providerId)
      token = await credentials.user.getIdToken()
      const credentialEmail = credentials?.user?.email || null
      logRocketIdentifyUser(credentialEmail, credentials.user.displayName)
      store.commit('wizard/setUserEmail', credentialEmail)
      emit('received-token', token)
    }

    const onImageFinished = () => emit('image-finished')

    watch(config, conf => {
      if (conf && !!conf.tenantConfiguration) {
        auth.value = useFirebaseAuth(window.location.hostname, conf)
      }
    })

    watch([email, password], ([email, password]) => {
      disabled.value = !(email && password)
    })

    return {
      email,
      password,
      disabled,
      passwordResetUrl,
      createAccountUrl,
      providerName: auth.value.providerName,
      signInOptions: auth.value.signInOptions,
      settings,

      signIn,
      onSubmit,
      onImageFinished,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@myndshft/color-palette/src/colors.scss';
@import '@myndshft/styles/src/components/cards.scss';
@import '@myndshft/styles/src/components/inputs.scss';
@import '@myndshft/styles/src/components/text.scss';
@import '@myndshft/styles/src/components/wl-button-mixin.scss';

.login-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .login-inner-container {
    width: 25rem;
    min-height: 26.25rem;

    .logo {
      margin-bottom: 1rem;
      min-width: 18.75rem;
      height: 3.5rem;
    }
  }
}

#login-options,
form {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: stretch;
  justify-content: center;
}

.login-option-button {
  margin: 10px 0 5px;
  display: flex;
  align-content: center;
  justify-content: center;
}

.field {
  position: relative;
  margin-bottom: 0.75rem;
}

.pad-left {
  padding-left: 2.5rem;
}

.margin-tb {
  margin: 0.75rem 0;
}

.login-button {
  @include wl-button-mixin($width: 18.75rem, $primary: var(--primary-color));
}

.link {
  min-height: 1rem;
  font-size: 0.875rem;
  line-height: 1.125rem;
  margin-bottom: 0.75rem;

  &-align-end {
    align-self: flex-end;
  }

  &-align-center {
    align-self: center;
    margin-top: 0.75rem;
  }
}

.google-sign-in {
  width: 80%;
}

.wl-ms-input:focus-visible {
  outline: none;
  box-shadow: 0 0 2px 0.02em var(--primary-color);
}

.align {
  &-right {
    align-self: flex-end;
  }
  &-center {
    align-self: center;
  }
}

.forgot-password {
  min-height: 1rem;
  font-size: 0.875rem;
  line-height: 1.125rem;
}

#error > * {
  margin: 10px 0 5px;
  display: flex;
  align-content: center;
  justify-content: center;
  color: $myndshft-required-pink;
}
</style>
