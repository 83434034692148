import { Insurance } from '@/types/form/prior-auth/insurance'
import { Insurance as MappedInsurance } from '@/types/api/prior-auth/insurance/index'
import { AddressState } from '@/types/api/prior-auth/common/address'

import { createUniqueId } from '@/modules/helpers/mapping'

export function mapInsurance(insurance: Insurance): MappedInsurance {
  return {
    unique_id: insurance?.payerUniqueId || null,
    member_id: insurance?.memberId || null,
    group_number: insurance?.groupNumber || null,
    subscriber_id: insurance?.subscriberId || null,
    subscriber_name: insurance?.subscriberName || null,
    primary: insurance?.primary ?? null,
    plan: {
      name: insurance?.planName || null,
      unique_id: insurance?.planUniqueId ? createUniqueId(insurance?.planUniqueId) : null,
      state: (insurance?.planState as AddressState) || null,
    },
  }
}
