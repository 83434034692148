import * as E from 'fp-ts/Either'
import * as I from 'io-ts'

/**
 * Create a unique id from a label
 * This will convert to lowercase, swap spaces with underscores,
 * and take out any special chars besides underscores.
 * @param str
 */
export const createUniqueId = (str: string) => {
  if (!str) return str
  const findSpaces = /[\s]/gm
  const findNonLettersNumbersUnderscore = /[^a-zA-Z0-9_]/gm
  return (
    str.toLowerCase().replace(findSpaces, '_').replace(findNonLettersNumbersUnderscore, '') || ''
  )
}

type MappedFieldStatus = 'required' | 'optional' | undefined
/**
 * Checks mapped field status and returns right
 * or left with field's input value
 * @param fieldValue
 * @param fieldStatus
 */
export function validateSubmissionField<T>(
  fieldValue: T,
  fieldStatus?: MappedFieldStatus
): E.Either<I.Errors, T | null> {
  if (fieldStatus === 'required') {
    if (fieldValue) {
      return E.right(fieldValue)
    } else {
      return E.left([
        {
          value: null,
          context: [],
          message: 'field value is required',
        },
      ])
    }
  }
  return E.right(fieldValue || null)
}

export function mapNetworkStatus(status: boolean | null) {
  switch (status) {
    case true: {
      return 'IN_NETWORK'
    }
    case false: {
      return 'OUT_OF_NETWORK'
    }
    default: {
      return null
    }
  }
}
