import { Provider } from '@/types/form/prior-auth/provider'
import { Provider as ResponseProvider } from '@/types/api/prior-auth/providers/provider'
import { AxiosResponse } from 'axios'
import { containsValue } from '@/modules/helpers/validate'
import { mapNetworkStatus } from '@/modules/helpers/mapping'
import { formatPhone } from '@/types/api/prior-auth/common/phone'

const format = (phone?: string | null): string | null => (phone ? formatPhone(phone) : null)

export function mapHttpResponseToProvider(response: AxiosResponse): Provider {
  return {
    information: {
      networkStatus: mapNetworkStatus(response.data.details.in_network),
      npi: response.data.npi || null,
      specialty: response.data.details.specialty || null,
      tin: response.data.details.tin || null,
      providerName: containsValue(response.data?.details?.organization_name)
        ? {
            type: 'organization',
            organizationName: response.data.details.organization_name || null,
          }
        : {
            type: 'individual',
            firstName: response.data.details.first_name || null,
            lastName: response.data.details.last_name || null,
          },
    },
    contact: {
      contactName: response.data.details.contact_name || null,
      email: response.data.details.email || null,
      fax: format(response.data.details.fax),
      phone: format(response.data.details.phone),
      phoneExtension: response.data.details.phone_ext || null,
    },
    address: {
      city: response.data.details.address.city || null,
      lineOne: response.data.details.address.line_one || null,
      lineTwo: response.data.details.address.line_two || null,
      lineThree: response.data.details.address.line_three || null,
      state: response.data.details.address.state || null,
      zipCode: response.data.details.address.zip_code || null,
    },
  }
}

export function mapCorrelationResponseToProvider(responseProvider: ResponseProvider): Provider {
  if (!responseProvider) {
    return { address: null, contact: null, information: null }
  }

  return {
    information: {
      networkStatus: mapNetworkStatus(responseProvider?.in_network),
      npi: responseProvider?.npi || null,
      specialty: responseProvider?.specialty || null,
      tin: responseProvider?.tin || null,
      providerName: containsValue(responseProvider?.organization_name)
        ? {
            type: 'organization',
            organizationName: responseProvider?.organization_name || null,
          }
        : {
            type: 'individual',
            firstName: responseProvider?.first_name || null,
            lastName: responseProvider?.last_name || null,
          },
    },
    contact: {
      contactName: responseProvider?.contact_name || null,
      email: responseProvider?.email || null,
      fax: format(responseProvider?.fax),
      phone: format(responseProvider?.phone),
      phoneExtension: responseProvider?.phone_ext || null,
    },
    address: {
      city: responseProvider?.address?.city || null,
      lineOne: responseProvider?.address?.line_one || null,
      lineTwo: responseProvider?.address?.line_two || null,
      lineThree: responseProvider?.address?.line_three || null,
      state: responseProvider?.address?.state || null,
      zipCode: responseProvider?.address?.zip_code || null,
    },
  }
}
