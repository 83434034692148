import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5cb098c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Auth = _resolveComponent("Auth")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Auth, {
      onReceivedToken: _ctx.onTokenReceived,
      onImageFinished: _ctx.onImageFinished
    }, null, 8, ["onReceivedToken", "onImageFinished"])
  ]))
}