import { State } from './state'

function getConfig(state: State) {
  return state.config
}

function myndviewUrl(state: State): string {
  return state.config.myndviewUrl
}

export const getters = { getConfig, myndviewUrl }
