import { Encounter } from '@/types/form/prior-auth/encounter'
import { Facility } from '@/types/form/prior-auth/facility'
import { Insurance } from '@/types/form/prior-auth/insurance'
import { Patient } from '@/types/form/prior-auth/patient'
import { Provider } from '@/types/form/prior-auth/provider'
import { SubmissionDetails } from '@/types/form/prior-auth/submission-details'
import { getInitialFacility, getInitialProvider, SubmissionState } from './state'
import { ProviderInformation } from '@/types/form/prior-auth/provider-information'
import { Contact } from '@/types/form/prior-auth/contact'
import { Address } from '@/types/form/prior-auth/address'
import { FacilityInformation } from '@/types/form/prior-auth/facility-information'
import * as WizardModels from '../../../models/wizard/fe'
import { EncounterDetails } from '@/types/form/prior-auth/encounter-details'
import { Id } from '@/types/form/prior-auth/id'
import { Procedure } from '@/types/form/prior-auth/procedures'
import { Attachment, AttachmentType } from '@/types/form/prior-auth/attachment'

function setPatient(state: SubmissionState, value: Patient) {
  state.patient = value
}

function setInsurances(state: SubmissionState, value: Array<Insurance & Id>) {
  state.insurances = value
}

function setOrderingProvider(state: SubmissionState, value: Provider) {
  state.providers = { ...state.providers, ordering: value }
}

function setServicingProvider(state: SubmissionState, value: Provider) {
  state.providers = { ...state.providers, servicing: value }
}

function setServicingProviderInformation(state: SubmissionState, value: ProviderInformation) {
  const provider = { ...state.providers.servicing, information: value }
  state.providers = { ...state.providers, servicing: provider }
}

function setServicingProviderContact(state: SubmissionState, value: Contact) {
  const provider = { ...state.providers.servicing, contact: value }
  state.providers = { ...state.providers, servicing: provider }
}

function setServicingProviderAddress(state: SubmissionState, value: Address) {
  const provider = { ...state.providers.servicing, address: value }
  state.providers = { ...state.providers, servicing: provider }
}

function setServicingProviderNetworkStatus(state: SubmissionState, value: any) {
  const provider = {
    ...state.providers.servicing,
    information: { ...(state.providers.servicing?.information || {}), networkStatus: value },
  }
  state.providers = { ...state.providers, servicing: provider }
}

function setPrimaryCareProvider(state: SubmissionState, value: Provider) {
  state.providers = { ...state.providers, pcp: value }
}

function resetPCP(state: SubmissionState): void {
  state.providers = { ...state.providers, pcp: getInitialProvider() }
}

function setReferringProvider(state: SubmissionState, value: Provider) {
  state.providers = { ...state.providers, referring: value }
}

function resetReferringProvider(state: SubmissionState): void {
  state.providers = { ...state.providers, referring: getInitialProvider() }
}

function setProviders(state: SubmissionState, providers: any) {
  state.providers = providers
}

function resetOrderingFacility(state: SubmissionState): void {
  state.facilities = { ...state.facilities, ordering: getInitialFacility() }
}

function setOrderingFacility(state: SubmissionState, value: Facility) {
  state.facilities = { ...state.facilities, ordering: value }
}

function setServicingFacility(state: SubmissionState, value: Facility) {
  state.facilities = { ...state.facilities, servicing: value }
}

function setServicingFacilityInformation(state: SubmissionState, value: FacilityInformation) {
  const facility = { ...state.facilities.servicing, information: value }
  state.facilities = { ...state.facilities, servicing: facility }
}

function setServicingFacilityNetworkStatus(state: SubmissionState, value: any) {
  const facility = {
    ...state.facilities.servicing,
    information: { ...(state.facilities.servicing?.information || {}), networkStatus: value },
  }
  state.facilities = { ...state.facilities, servicing: facility }
}

function setServicingFacilityContact(state: SubmissionState, value: Contact) {
  const facility = { ...state.facilities.servicing, contact: value }
  state.facilities = { ...state.facilities, servicing: facility }
}

function setServicingFacilityAddress(state: SubmissionState, value: Address) {
  const facility = { ...state.facilities.servicing, address: value }
  state.facilities = { ...state.facilities, servicing: facility }
}

function setFacilities(state: SubmissionState, facilities: any) {
  state.facilities = facilities
}

function setEncounter(state: SubmissionState, value: Encounter): void {
  state.encounter = value
}

function setEncounterServiceCategory(state: SubmissionState, value: EncounterDetails): void {
  state.encounter = {
    ...state.encounter,
    details: value,
  }
}

function setAdditionalInformation(
  state: SubmissionState,
  value: WizardModels.AdditionalInformationProperty[]
): void {
  state.additionalInformation = value
}

function setPrimaryProcedure(state: SubmissionState, value: Procedure): void {
  const primaryProcedure = { ...value } as Procedure
  state.encounter = {
    ...state.encounter,
    procedures: {
      primaryProcedure,
      additionalProcedures: state.encounter?.procedures?.additionalProcedures,
    },
  }
}

function setSubmissionDetails(state: SubmissionState, value: SubmissionDetails): void {
  state.details = { ...state.details, ...value }
}

function addAttachment(state: SubmissionState, value: Attachment) {
  switch (value.type) {
    case AttachmentType.HISTORY_AND_PHYSICAL:
      state.historyAndPhysical = value
      break
    case AttachmentType.SIGNED_ORDER:
      state.signedOrder = value
      break
    case AttachmentType.MISC_ATTACHMENT: {
      const attachmentIndex = state.miscAttachments.findIndex(
        attachment => attachment.name === value.name
      )
      const newAttachments = [...state.miscAttachments]
      if (attachmentIndex > -1) {
        newAttachments.splice(attachmentIndex, 1)
      }
      state.miscAttachments = [...newAttachments, value]
    }
  }
}

function removeAttachment(state: SubmissionState, value: Attachment) {
  const removeAttachmentFromMiscAttachments = () => {
    const index = state.miscAttachments.findIndex(attachment => {
      return value.attachmentId === attachment.attachmentId
    })
    if (index !== -1) {
      const newAttachments = [...state.miscAttachments]
      newAttachments.splice(index, 1)
      state.miscAttachments = newAttachments
    }
  }

  switch (value.type) {
    case AttachmentType.HISTORY_AND_PHYSICAL:
      state.historyAndPhysical = null
      break
    case AttachmentType.SIGNED_ORDER:
      state.signedOrder = null
      break
    case AttachmentType.MISC_ATTACHMENT:
      removeAttachmentFromMiscAttachments()
  }
}

export const mutations = {
  setSubmissionDetails,
  setPatient,
  setInsurances,
  setOrderingProvider,
  setServicingProvider,
  setServicingProviderInformation,
  setServicingProviderContact,
  setServicingProviderAddress,
  setPrimaryCareProvider,
  resetPCP,
  setReferringProvider,
  resetReferringProvider,
  resetOrderingFacility,
  setOrderingFacility,
  setServicingFacility,
  setServicingFacilityInformation,
  setServicingFacilityContact,
  setServicingFacilityAddress,
  setEncounter,
  setAdditionalInformation,
  setEncounterServiceCategory,
  setProviders,
  setFacilities,
  setPrimaryProcedure,
  setServicingFacilityNetworkStatus,
  setServicingProviderNetworkStatus,
  addAttachment,
  removeAttachment,
}
