import { AdditionalInformationProperty } from './addtional-information'

export enum Requirement {
  required = 'required',
  optional = 'optional',
}

export interface RequiredProviderAndFacilitySections {
  providers: string[]
  facilities: string[]
}

export interface AddressRequiredFields {
  lineOne: Requirement
  lineTwo: Requirement
  lineThree: Requirement
  city: Requirement
  state: Requirement
  zipCode: Requirement
  country: Requirement
}

export interface AttachmentRequiredFields {
  signedOrder: Requirement
  historyAndPhysical: Requirement
}

export interface DetailsRequiredFields {
  urgency: Requirement
  type: Requirement
  previousSubmission: Requirement
  outOfNetworkRationale: Requirement
}

export interface InsuranceRequiredFields {
  name: Requirement
  groupNumber: Requirement
  subscriberId: Requirement
  subscriberName: Requirement
  memberId: Requirement
  planName: Requirement
  planState: Requirement
}

export interface PatientRequiredFields {
  id: Requirement
  medicalRecordNumber: Requirement
  drugAllergies: Requirement
  firstName: Requirement
  middleName: Requirement
  lastName: Requirement
  dateOfBirth: Requirement
  ethnicity: Requirement
  gender: Requirement
  phone: Requirement
  phoneExtension: Requirement
  height: Requirement
  weight: Requirement
  address: AddressRequiredFields
  primaryInsurance: InsuranceRequiredFields
  additionalInsurance: InsuranceRequiredFields
}

export interface ContactRequiredFields {
  name: Requirement
  phone: Requirement
  phoneExtension: Requirement
  fax: Requirement
  email: Requirement
}

export interface FacilityRequiredFields {
  npi: Requirement
  tin: Requirement
  organizationName: Requirement
  networkStatus: Requirement
  contact: ContactRequiredFields
  address: AddressRequiredFields
}

export interface ProviderRequiredFields {
  npi: Requirement
  tin: Requirement
  firstName: Requirement
  lastName: Requirement
  organizationName: Requirement
  specialty: Requirement
  networkStatus: Requirement
  contact: ContactRequiredFields
  address: AddressRequiredFields
}

export interface ProvidersRequiredFields {
  ordering: ProviderRequiredFields
  servicing: ProviderRequiredFields
  pcp: ProviderRequiredFields
  referring: ProviderRequiredFields
}

export interface FacilitiesRequiredFields {
  ordering: FacilityRequiredFields
  servicing: FacilityRequiredFields
}

export interface DiagnosisRequiredFields {
  code: Requirement
  description: Requirement
}

export interface ProcedureRequiredFields {
  code: Requirement
  modifier: Requirement
  description: Requirement
  units: Requirement
  startDate: Requirement
  endDate: Requirement
  diagnosis: DiagnosisRequiredFields
}

export interface EncounterRequiredFields {
  serviceType: Requirement
  locationType: Requirement
  inpatient: Requirement
  procedure: ProcedureRequiredFields
}

export interface RequiredFields {
  details: DetailsRequiredFields
  patient: PatientRequiredFields
  providers: ProvidersRequiredFields
  facilities: FacilitiesRequiredFields
  encounter: EncounterRequiredFields
  attachments: AttachmentRequiredFields
}

export interface Properties {
  requiredFields: RequiredFields
  additionalInformation: AdditionalInformationProperty[]
  sections: RequiredProviderAndFacilitySections
}
