import { isValidNumberForRegion } from 'libphonenumber-js'
import * as I from 'io-ts'

function digitConversion(phone: string): string | null {
  if (phone && phone.length < Number.MAX_SAFE_INTEGER) {
    return phone.replace(/^(1|\+1)|[^\d]+/gm, '')
  }
  return null
}

export const formatPhone = (phone: string): string | null => {
  const digits = digitConversion(phone)
  let formattedPhone: string | null
  if (digits) {
    if (digits.length < 4) {
      formattedPhone = `${digits.substring(0, digits.length)}`
    } else if (digits.length < 7) {
      formattedPhone = `(${digits.substring(0, 3)}) ${digits.substring(3, digits.length)}`
    } else if (digits.length < 11) {
      formattedPhone = `(${digits.substring(0, 3)}) ${digits.substring(3, 6)}-${digits.substring(
        6,
        11
      )}`
    } else {
      formattedPhone = digits
    }
  } else {
    formattedPhone = phone
  }

  return formattedPhone
}

export const validatePhone = (phone: string): boolean => {
  const test = formatPhone(phone)
  if (test != null) {
    return isValidNumberForRegion(test, 'US')
  }
  return false
}

export interface PhoneBrand {
  readonly Phone: unique symbol
}

export type Phone = I.Branded<string, PhoneBrand>

export const Phone = I.brand(
  I.string,
  (e): e is Phone => I.string.is(e) && validatePhone(e),
  'Phone'
)
