const containsValue = (valueToValidate?: any) => {
  if (!valueToValidate) {
    return false
  }

  switch (typeof valueToValidate) {
    case 'string':
      return valueToValidate !== ''
    case 'number':
      return valueToValidate !== -1 && valueToValidate !== 0
    case 'object':
      return Object.keys(valueToValidate).length > 0
    default:
      return false
  }
}

function checkProperties(obj: any) {
  for (const key in obj) {
    if (key == 'networkStatus') {
      continue
    }
    if (obj[key]) return true
  }
  return false
}

function isFieldRequired(fieldRequired?: string, forceRequired?: boolean) {
  return !!(fieldRequired?.toLowerCase() === 'required' || forceRequired)
}
function shouldHideField(required: string, val: string, showAlways: boolean) {
  return !isFieldRequired(required) && !val && !showAlways
}

export { checkProperties, containsValue, shouldHideField, isFieldRequired }
