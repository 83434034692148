import Swal, { SweetAlertOptions } from 'sweetalert2'
import { expireAllCookies } from '@/modules/cookies/cookies'
import { useSubmissionApi } from '@/modules/submission/http'
import { mapAttachments, mapSubmission } from '@/mappers/submission/submission'
import { isDraftValidForSave } from '@/modules/submission/validation'
import { logout } from '@/modules/myndcontrol/http'

const ONE_MINUTE_IN_MILLISECONDS = 60000
const TEN_SECONDS_IN_MILLISECONDS = 10000
const POPUP_OPTIONS: SweetAlertOptions = {
  toast: true,
  position: 'bottom-end',
  showConfirmButton: false,
  title: 'Logout Warning',
  icon: 'warning',
  customClass: {
    htmlContainer: 'inactivity-alert',
  },
}

const clearTimeoutFromId = (timeoutId: number) => {
  if (timeoutId !== -1) {
    clearTimeout(timeoutId)
  }
}
const clearIntervalFromId = (intervalId: number) => {
  if (intervalId !== -1) {
    clearInterval(intervalId)
  }
}
const saveDraftBeforeLogout = async (store: any) => {
  const submission = {
    details: store.state.submission.details,
    patient: store.state.submission.patient,
    insurances: store.state.submission.insurances,
    providers: store.state.submission.providers,
    facilities: store.state.submission.facilities,
    encounter: store.state.submission.encounter,
    additionalInformation: store.state.submission.additionalInformation,
  }
  const attachments = {
    signedOrder: store.state.submission.signedOrder,
    historyAndPhysical: store.state.submission.historyAndPhysical,
    miscAttachments: store.state.submission.miscAttachments,
  }
  const session = store.state.wizard.session
  const token = session?.token
  const csrf = store.state?.csrf
  const clientId = session?.clientId
  const submissionId = session?.submissionId
  const correlationId = session?.correlationId

  const mappedSubmission = mapSubmission(submission)
  const mappedAttachments = mapAttachments(attachments)

  if (token && clientId && submissionId && correlationId && isDraftValidForSave(mappedSubmission)) {
    const { saveDraft } = useSubmissionApi(token, clientId, csrf)
    await saveDraft(correlationId, submissionId, {
      submission: mappedSubmission,
      attachments: mappedAttachments,
    })
  } else {
    console.warn('submission invalid for saving state')
  }
  return true
}

export class InactivityTracker {
  private static mouseEvents = ['click', 'mousemove', 'mouseup', 'mousedown', 'wheel']
  private static keyboardEvents = ['keyup']
  private static windowEvents = ['scroll']
  private static inactivityWarningTimeoutId: any = -1
  private static inactivityLogoutTimeoutId: any = -1
  private static inactivityPopupIntervalId: any = -1
  private static checkActiveTimeoutId: any = -1
  private static setActiveTimeoutId: any = -1
  private static inactivityWarningTimeoutInMilliseconds = 900000
  private static inactivityLogoutAfterWarningTimeoutInMilliseconds = 300000
  private static millisecondsCountDown = 300000
  private static millisecondsForCheckActive = 30 * 1000
  private static inactivityPopupRef: any = null
  private static storeRef: any = null

  private static getWarningMessageForDisplay() {
    const minutesForMessage =
      InactivityTracker.millisecondsCountDown / ONE_MINUTE_IN_MILLISECONDS > 1
        ? Math.ceil(InactivityTracker.millisecondsCountDown / ONE_MINUTE_IN_MILLISECONDS)
        : '< 1'
    const plural = minutesForMessage > 1 ? 's' : ''
    return `Are you still there? If you're around move your mouse a little! HIPAA requires me to sign you out in ${minutesForMessage} minute${plural} if you're still inactive.`
  }

  private static startDisplayMessageInterval() {
    return setInterval(() => {
      InactivityTracker.millisecondsCountDown =
        InactivityTracker.millisecondsCountDown - TEN_SECONDS_IN_MILLISECONDS
      InactivityTracker.inactivityPopupRef.getHtmlContainer().innerText =
        InactivityTracker.getWarningMessageForDisplay()
    }, TEN_SECONDS_IN_MILLISECONDS)
  }

  private static showInactivityWarningPopup() {
    InactivityTracker.millisecondsCountDown =
      InactivityTracker.inactivityLogoutAfterWarningTimeoutInMilliseconds -
      InactivityTracker.inactivityWarningTimeoutInMilliseconds
    InactivityTracker.inactivityPopupIntervalId = InactivityTracker.startDisplayMessageInterval()

    const message = InactivityTracker.getWarningMessageForDisplay()
    InactivityTracker.inactivityPopupRef.fire({
      text: message,
    })
  }

  /**
   * Save the users draft and log them out for inactivity.
   * @private
   */
  private static async expireUserForInactivity() {
    try {
      await saveDraftBeforeLogout(InactivityTracker.storeRef)

      if (
        InactivityTracker.storeRef?.getters['wizard/getFeatureFlags']?.has(
          'isEnabledToUseTheSessionCookieToFetchTheToken'
        )
      ) {
        await InactivityTracker?.storeRef?.dispatch('wizard/sessionLogout')
      } else {
        await logout(
          InactivityTracker.storeRef?.state?.wizard?.session?.token,
          InactivityTracker.storeRef?.state?.wizard?.session?.clientId,
          InactivityTracker.storeRef?.state?.csrf
        )
      }
    } catch (error: any) {
      console.warn('failed attempt to save draft before logout')
    }

    expireAllCookies()
    window.onbeforeunload = null
    location.reload()
  }

  private static setupDocumentEvents() {
    const events = [...this.mouseEvents, ...this.keyboardEvents, ...this.windowEvents]
    for (const event of events) {
      document.addEventListener(event, () => InactivityTracker.setActiveAndResetInactivityMonitor())
    }
  }

  private static async setActive() {
    // this would be a server error failure if this fails logging the use out
    if (!(await InactivityTracker?.storeRef?.dispatch('wizard/setActive'))) {
      InactivityTracker?.storeRef?.dispatch('wizard/sessionLogout')
    }
  }

  //MONITORING

  /**
   * this is checking every 30 seconds for app wide activity in order to keep the open app active.
   * @private
   */
  private static activityCheckShortTimeout() {
    clearTimeout(InactivityTracker.checkActiveTimeoutId)
    InactivityTracker.checkActiveTimeoutId = setTimeout(() => {
      InactivityTracker.storeRef?.dispatch('wizard/checkActive')?.then((active: boolean) => {
        if (active) {
          // if active, then reset the checks and clear the check active short term checks.
          InactivityTracker.resetWarningDisplayAndLogout()
        } else {
          // reset the check and keep checking
          InactivityTracker.activityCheckShortTimeout()
        }
      })
    }, InactivityTracker.millisecondsForCheckActive)
  }

  /**
   * This is the warning banner timeout, which will display the warning if no activity is found before the timeout is done.
   * @private
   */

  private static warningDisplayLongTimeout() {
    clearTimeout(InactivityTracker.inactivityWarningTimeoutId)
    InactivityTracker.inactivityWarningTimeoutId = setTimeout(() => {
      // If this becomes active then check for other application activity.
      InactivityTracker.storeRef?.dispatch('wizard/checkActive')?.then((active: boolean) => {
        if (active) {
          // if they are active in other apps then reset the monitoring.
          InactivityTracker.resetWarningDisplayAndLogout()
        } else {
          // show the warning popup and start short term activity checking.
          InactivityTracker.showInactivityWarningPopup()
          InactivityTracker.activityCheckShortTimeout()
        }
      })
    }, InactivityTracker.inactivityWarningTimeoutInMilliseconds)
  }

  /**
   * This is the timeout that will eventually log a user out if no movement in the app or no other application activity is found
   * @private
   */
  private static logoutTimeout() {
    clearTimeout(InactivityTracker.inactivityLogoutTimeoutId)
    InactivityTracker.inactivityLogoutTimeoutId = setTimeout(() => {
      InactivityTracker.storeRef?.dispatch('wizard/checkActive')?.then((active: boolean) => {
        if (active) {
          InactivityTracker.resetWarningDisplayAndLogout()
        } else {
          InactivityTracker.expireUserForInactivity().finally()
        }
      })
    }, InactivityTracker.inactivityLogoutAfterWarningTimeoutInMilliseconds)
  }

  private static resetWarningDisplayAndLogout() {
    if (
      InactivityTracker.inactivityPopupRef &&
      InactivityTracker.inactivityPopupRef
        ?.getHtmlContainer()
        ?.classList?.contains('inactivity-alert')
    ) {
      InactivityTracker.inactivityPopupRef?.close()
    }

    clearTimeout(InactivityTracker.checkActiveTimeoutId)
    clearIntervalFromId(InactivityTracker.inactivityPopupIntervalId)

    InactivityTracker.warningDisplayLongTimeout()
    InactivityTracker.logoutTimeout()
  }

  /**
   * Sets the activity status and resets the inactivity monitors.
   */
  static setActiveAndResetInactivityMonitor() {
    InactivityTracker.resetWarningDisplayAndLogout()
    // debounced is used to prevent spamming the server, only call every 30 seconds of activity
    if (this.setActiveTimeoutId === -1) {
      this.setActiveTimeoutId = setTimeout(
        () => (this.setActiveTimeoutId = -1),
        this.millisecondsForCheckActive
      )
      InactivityTracker.setActive().finally()
    }
  }

  static startInactivityMonitoring(
    warningWaitInMilliseconds: number,
    logoutWaitInMilliseconds: number,
    store: any
  ) {
    InactivityTracker.storeRef = store
    InactivityTracker.inactivityWarningTimeoutInMilliseconds = warningWaitInMilliseconds
    InactivityTracker.inactivityLogoutAfterWarningTimeoutInMilliseconds =
      logoutWaitInMilliseconds + warningWaitInMilliseconds
    // creates the listener which will fire the first reset on user movement.
    InactivityTracker.setupDocumentEvents()
    InactivityTracker.inactivityPopupRef = Swal.mixin(POPUP_OPTIONS)
    InactivityTracker.resetWarningDisplayAndLogout()
  }

  static stopInactivityMonitoring() {
    clearTimeoutFromId(InactivityTracker.inactivityWarningTimeoutId)
    clearTimeoutFromId(InactivityTracker.inactivityLogoutTimeoutId)
    clearIntervalFromId(InactivityTracker.inactivityPopupIntervalId)
    InactivityTracker.inactivityWarningTimeoutId = -1
    InactivityTracker.inactivityLogoutTimeoutId = -1
    InactivityTracker.inactivityPopupIntervalId = -1
    InactivityTracker.checkActiveTimeoutId = -1
    InactivityTracker.setActiveTimeoutId = -1
    InactivityTracker.storeRef = null
  }
}
