<template>
  <transition name="fade">
    <div class="dim-background" v-if="showLoading">
      <div class="loading-spinner"></div>
    </div>
  </transition>
  <login @imageFinished="onImageFinished"></login>
</template>

<script lang="ts">
import { ref, defineComponent, onMounted, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import Login from '@/components/login/login.vue'
import { getMyndviewUrl } from '@/modules/helpers/http'
import { expireAllCookies, getTokenFromCookies } from '@/modules/cookies/cookies'
import { verify } from '@/modules/myndcontrol/http'

export default defineComponent({
  name: 'LoginView',
  components: { Login },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    const authorized = computed(() => store.getters['wizard/isAuthorized'])
    const csrf = computed(() => store.getters['getCSRFToken'])
    const errorShouldAddCorrelation = computed(
      () => store.getters['wizard/getErrorShouldAddCorrelation']
    )
    const features = computed(() => store.getters['wizard/getFeatureFlags'])
    const showLoading = ref(true)
    const clientId = ref<string | null>(null)
    const correlationId = ref<string | null>(null)
    const imageFinished = ref<boolean>(false)
    const tokenCheckFinished = ref<boolean>(false)
    const onImageFinished = () => (imageFinished.value = true)

    const checkAndRouteIfActiveSession = () => store.dispatch('wizard/verifySession')

    const checkForToken = async () => {
      const cookieToken = getTokenFromCookies()
      if (cookieToken) {
        if (clientId.value && cookieToken) {
          const verified = await verify(cookieToken, clientId.value, csrf.value)
          if (verified) {
            store.commit('wizard/setToken', cookieToken)
          }
        }
      }
    }

    const setErrorFromMissingClientIdOrCorrelation = async () => {
      if (!clientId.value) {
        await store.dispatch('errorComponent/setNoClientIdError')
      } else if (!correlationId.value) {
        const backToMyndview = () => {
          const url = getMyndviewUrl(
            clientId.value,
            errorShouldAddCorrelation.value ? correlationId.value : ''
          )
          expireAllCookies()
          window.onbeforeunload = null
          window.location.assign(url)
        }
        await store.dispatch('errorComponent/setNoCorrelationIdError', backToMyndview)
      }
    }

    watch(
      [imageFinished, tokenCheckFinished],
      ([imageFinished, tokenFinished]) => (showLoading.value = !(imageFinished && tokenFinished))
    )

    watch(features, async (flags: Set<string>) => {
      if (flags) {
        if (flags.has('isEnabledToUseTheSessionCookieToFetchTheToken')) {
          await checkAndRouteIfActiveSession()
        } else {
          await checkForToken()
        }
        tokenCheckFinished.value = true
      }
    })

    watch([tokenCheckFinished, authorized], ([tcf, auth]) => {
      if (tcf && auth) {
        router.push({ name: 'SelectPrimaryProcedure', query: { ...route.query } })
      }
    })

    onMounted(() => {
      clientId.value = route.query.clientId?.toString() || null
      correlationId.value = route.query.correlationId?.toString() || null

      store.commit('wizard/setClientId', clientId.value)
      store.dispatch('wizard/setCorrelationId', correlationId.value)

      if (!clientId.value || !correlationId.value) {
        setErrorFromMissingClientIdOrCorrelation()
        router.push({ name: 'Error' })
      }
    })

    return {
      showLoading,

      onImageFinished,
    }
  },
})
</script>
