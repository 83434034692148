import { State } from './state'
import { track } from '@/modules/logrocket/logrocket'

enum EVENT_TYPE {
  PROVIDERS_DISPLAYED_COUNT_EVENT = 'providers-displayed-count-event',
  FACILITIES_DISPLAYED_COUNT_EVENT = 'facilities-displayed-count-event',
  SHARED_NPI_COUNT_EVENT = 'shared-npi-count-event',
}

const sendEvent = (event: string, value: number) => {
  track(event, { count: value })
}

const getNPICounts = (obj: any, allNpis?: Record<string, number>): Record<string, number> => {
  const npis: Record<string, number> = allNpis ?? {}

  for (const value of Object.values(obj)) {
    const npi: string = (value as any)?.information?.npi
    if (npi && !npis?.[npi]) {
      npis[npi] = 1
    } else if (npi) {
      npis[npi]++
    }
  }

  return npis
}

const getMaxNPICount = (allNpis: Record<string, number>): number => {
  let maxCount = 0
  for (const value of Object.values(allNpis)) {
    maxCount = value > maxCount ? value : maxCount
  }
  return maxCount
}

const sendProvidersCountEvent = (state: any) => {
  sendEvent(EVENT_TYPE.PROVIDERS_DISPLAYED_COUNT_EVENT, state.providersShownCount)
}
const sendFacilitiesCountEvent = (state: any) => {
  sendEvent(EVENT_TYPE.FACILITIES_DISPLAYED_COUNT_EVENT, state.facilitiesShownCount)
}
const sendSharedNPICountEvent = (rootState: any) => {
  const providers: any = rootState?.submission?.providers
  const facilities: any = rootState?.submission?.facilities

  if (providers && facilities) {
    let npis = getNPICounts(providers)
    npis = getNPICounts(facilities, npis)
    const maxCount = getMaxNPICount(npis)

    sendEvent(EVENT_TYPE.SHARED_NPI_COUNT_EVENT, maxCount)
  }
}

const sendMetrics = ({ rootState, state }: { rootState: any; state: State }): void => {
  sendProvidersCountEvent(state)
  sendFacilitiesCountEvent(state)
  sendSharedNPICountEvent(rootState)
}

export const actions = {
  sendMetrics,
}
