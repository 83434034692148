import { DiagnosticLab } from '@/types/form/prior-auth/encounter-details'
import { DiagnosticLab as MappedDiagnosticLab } from '@/types/api/prior-auth/encounter/details/diagnostic-lab'

export function mapDiagnosticLab(diagnosticLab: DiagnosticLab): MappedDiagnosticLab {
  if (diagnosticLab.specimenCollected) {
    return {
      category: 'diagnostic_lab',
      specimen_collected: diagnosticLab?.specimenCollected,
      specimen_collected_date:
        diagnosticLab?.specimenCollectedDate?.toISOString().replace('Z', '+00:00') || null,
    }
  }
  return {
    category: 'diagnostic_lab',
    specimen_collected: diagnosticLab?.specimenCollected ?? null,
  }
}
