import { ActionContext } from 'vuex'
import { State } from './state'
import { ErrorState, ErrorMutationTypes } from '@/types/error-component/error-component'
import { AnyFunction } from '@/types/function-type'
import { Config as WhitelabelConfig } from '@myndshft/whitelabeling/lib/index.js'
import { getMyndviewUrl } from '@/modules/helpers/http'
import { expireAllCookies } from '@/modules/cookies/cookies'

type ActionCtx = ActionContext<State, State>

const backToMyndview = () => {
  const url = getMyndviewUrl(null, null)
  expireAllCookies()
  window.onbeforeunload = null
  window.location.assign(url)
}

export const actions = {
  setErrorState({ commit }: ActionCtx, errorProps: Partial<ErrorState>) {
    commit(ErrorMutationTypes.SET_BANNER_URL, errorProps.bannerUrl || '')
    commit(ErrorMutationTypes.SET_BANNER_TITLE, errorProps.bannerTitle || '')
    commit(ErrorMutationTypes.SET_MESSAGE_TITLE, errorProps.messageTitle || '')
    commit(ErrorMutationTypes.SET_MESSAGE_LINE_ONE, errorProps.messageLineOne || '')
    commit(ErrorMutationTypes.SET_MESSAGE_LINE_TWO, errorProps.messageLineTwo || '')
    commit(ErrorMutationTypes.SET_MESSAGE_LINE_THREE, errorProps.messageLineThree || '')
    commit(ErrorMutationTypes.SET_TITLE_ALIGN, errorProps.titleAlign || 'center')
    commit(ErrorMutationTypes.SET_MESSAGE_ALIGN, errorProps.messageAlign || 'left')
    commit(ErrorMutationTypes.SET_BUTTON_LABEL, errorProps.buttonLabel || 'Continue')
    commit(ErrorMutationTypes.SET_ACTION, errorProps.action || null)
  },
  setNoClientIdError({ dispatch, rootGetters }: ActionCtx) {
    const whiteLabelConfig: WhitelabelConfig = rootGetters['whiteLabel/whitelabelConfig']
    const noClientIdSettings: Partial<ErrorState> = {
      bannerUrl: whiteLabelConfig?.banner?.imageUrl,
      bannerTitle: whiteLabelConfig?.banner?.title,
      messageTitle: 'Uh oh!',
      messageLineOne: 'No client ID was provided in the URL',
      messageAlign: 'center',
      buttonLabel: 'Back to Myndview',
      action: backToMyndview,
    }
    dispatch('setErrorState', noClientIdSettings).finally()
  },
  setNoCorrelationIdError({ dispatch, rootGetters }: ActionCtx, action: AnyFunction) {
    const whiteLabelConfig: WhitelabelConfig = rootGetters['whiteLabel/whitelabelConfig']
    const noCorrelationIdSettings: Partial<ErrorState> = {
      bannerUrl: whiteLabelConfig?.banner?.imageUrl,
      bannerTitle: whiteLabelConfig?.banner?.title,
      messageTitle: 'Uh oh!',
      messageLineOne:
        'A correlation id is needed to determine if a PA is required and to supply any needed PA Submission details',
      messageAlign: 'center',
      buttonLabel: 'Run a Prior Authorization Requirements Transaction in Myndview',
      action,
    }
    dispatch('setErrorState', noCorrelationIdSettings).finally()
  },
  setErrorFromTemplate({ dispatch, rootGetters }: ActionCtx, errorProps: Partial<ErrorState>) {
    const whiteLabelConfig: WhitelabelConfig = rootGetters['whiteLabel/whitelabelConfig']
    const errorTemplate: Partial<ErrorState> = {
      bannerUrl: whiteLabelConfig?.banner?.imageUrl,
      bannerTitle: whiteLabelConfig?.banner?.title,
      messageTitle: errorProps.messageTitle || 'Uh oh!',
      messageLineOne: errorProps.messageLineOne || 'An unknown error has occurred',
      messageAlign: errorProps.messageAlign,
      buttonLabel: errorProps.buttonLabel,
      action: errorProps.action,
    }
    dispatch('setErrorState', errorTemplate).finally()
  },
}
