import axios from 'axios'
import { getBFFRoute } from '@/modules/helpers/http'

const bff = getBFFRoute()
const fetchFormPopulationData = async (
  jwt: string,
  clientId: string,
  csrf: string,
  correlationId: string | null
) => {
  const params: Record<string, string> = {}
  if (correlationId) params['correlation_id'] = correlationId

  const response = await axios.get(`${bff}/api/transaction`, {
    responseType: 'json',
    params,
    headers: {
      Authorization: `Bearer ${jwt}`,
      'x-myndshft-client-id': clientId,
      'x-csrf-token': csrf,
    },
  })

  return response?.data
}

export const useFormPopulationRequest = (jwt: string, clientId: string, csrf: string) => ({
  getFormPopulationData: async (correlationId: string) =>
    await fetchFormPopulationData(jwt, clientId, csrf, correlationId),
})
