import { Patient as PatientInForm } from '@/types/form/prior-auth/patient'
import { Patient as PatientInTransaction } from '@/types/api/prior-auth/patient/index'
import { formatPhone } from '@/types/api/prior-auth/common/phone'

export function mapPatient(patient: PatientInTransaction): PatientInForm {
  const format = (phone?: string | null): string | null => (phone ? formatPhone(phone) : null)
  if (!patient) return {}

  return {
    information: {
      patientId: patient?.patient_id || null,
      firstName: patient?.first_name || null,
      middleName: patient?.middle_name || null,
      lastName: patient?.last_name || null,
      dateOfBirth: patient?.date_of_birth || null,
      ethnicity: patient?.ethnicity || null,
      phone: format(patient?.phone),
      phoneExtension: patient?.phone_ext || null,
      gender: patient?.gender || null,
      height: patient?.height || null,
      weight: patient?.weight || null,
      medicalRecordNumber: patient?.medical_record_number || null,
    },
    address: {
      lineOne: patient?.address?.line_one || null,
      lineTwo: patient?.address?.line_two || null,
      lineThree: patient?.address?.line_three || null,
      city: patient?.address?.city || null,
      state: patient?.address?.state || null,
      zipCode: patient?.address?.zip_code || null,
    },
    drugAllergies: patient?.drug_allergies || [],
  }
}
