export const isDraftValidForSave = (submission: any) => {
  const hasFirstAndLastName: boolean =
    submission?.patient?.first_name && submission?.patient?.last_name
  const hasMemberId: boolean =
    submission?.insurance?.length > 0 && submission?.insurance?.[0]?.member_id
  const primaryInsurance = submission?.insurance?.find((i: any) => i?.primary)
  const hasPrimaryInsurance: boolean =
    submission?.insurance?.length > 0 && primaryInsurance && primaryInsurance?.unique_id
  return hasPrimaryInsurance && hasMemberId && hasFirstAndLastName
}
