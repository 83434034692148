import axios from 'axios'

import * as HTTP from '@/modules/helpers/http'
import { getBFFRoute } from '@/modules/helpers/http'
import { expireAllCookies } from '@/modules/cookies/cookies'

export const postAuthorization = async (token: string, csrf: string): Promise<any> => {
  const path = `${getBFFRoute()}/api/authorize`
  const body = { token }
  const config = {
    headers: { 'content-type': 'application/json', 'x-csrf-token': csrf },
  }
  const response = await axios.post(path, body, config)
  return { token: response?.data?.token }
}

export const verify = async (jwt: string, clientId: string, csrf: string): Promise<any> => {
  const path = `${getBFFRoute()}/api/authorize/verify`
  const config = {
    headers: {
      Authorization: `Bearer ${jwt}`,
      'x-csrf-token': csrf,
      'x-myndshft-client-id': clientId,
      'content-type': 'application/json',
    },
  }

  await axios.get(path, config).catch(_err => {
    expireAllCookies()
    window.onbeforeunload = null
    return false
  })
  return true
}

export const logout = async (jwt: string, clientId: string, csrf: string): Promise<any> => {
  const path = `${getBFFRoute()}/api/authorize/logout`
  const config = {
    headers: {
      Authorization: `Bearer ${jwt}`,
      'x-myndshft-client-id': clientId,
      'x-csrf-token': csrf,
      'content-type': 'application/json',
    },
  }

  return await axios
    .get(path, config)
    .then(() => true)
    .catch(() => false)
}

export const getDiagnosisCodes = (code: string, csrf: string) =>
  HTTP.createGetRequest(`${getBFFRoute()}/api/diagnosis?code=${code}`, {
    headers: {
      'x-csrf-token': csrf,
    },
  })

export const getProcedureCodeList = async (code: string, csrf: string) => {
  const path = `${getBFFRoute()}/api/procedures?code=${code}`
  const response = await axios
    .get(path, {
      headers: {
        'x-csrf-token': csrf,
      },
    })
    .catch(err => {
      console.error(err.message)
    })
  return response?.data || null
}
