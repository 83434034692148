import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { wizardSubRoutes } from '@/router/wizard'
import store from '../store'
import LoginView from '@/views/LoginView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: LoginView,
  },
  {
    path: '/wizard',
    name: 'Wizard',
    component: () => import('@/views/WizardView.vue'),
    meta: { requiresAuth: true },
    children: wizardSubRoutes,
  },
  {
    path: '/primary',
    name: 'SelectPrimaryProcedure',
    component: () => import('@/views/SelectPrimaryProcedureView.vue'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (from.name !== 'Login') {
        next({ path: '/', query: { ...to.query } })
      } else {
        next()
      }
    },
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import('@/views/ErrorView.vue'),
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/',
    meta: {
      hidden: true,
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  if (!!from.query && Object.keys(from.query)?.length) {
    to.query = { ...to.query, ...from.query }
  }

  if ((!from.name || from.name === 'Error') && to.name === 'Error') {
    next({ path: '/', query: { ...to.query } })
  } else if (to.matched.some(record => record.meta.requiresAuth)) {
    const isAuthorized = store.getters['wizard/isAuthorized']
    if (isAuthorized) {
      next()
    } else {
      next({ path: '/', query: { ...to.query } })
    }
  } else {
    next()
  }
})

export default router
