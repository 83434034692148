export interface Session {
  token?: string | null
  payer?: string | null
  plan?: string | null
  state?: string | null
  healthCareCategory?: string | null
  networkStatus?: string | null
  memberId?: string | null
  groupNumber?: string | null
  clientId?: string | null
  correlationId?: string | null
  submissionId?: string | null
  userEmail?: string | null
  formId?: string | null
  primaryCode?: string | null
  primaryCodeModifier?: string | null
  additionalCodes?: string[] | null
  lastActive?: Date | null
  reVerify?: boolean | null
}

export const emptySession: Session = {
  token: null,
  payer: null,
  plan: null,
  state: null,
  healthCareCategory: null,
  networkStatus: null,
  memberId: null,
  groupNumber: null,
  clientId: null,
  correlationId: null,
  submissionId: null,
  formId: null,
  primaryCode: null,
  additionalCodes: null,
  primaryCodeModifier: null,
  lastActive: null,
  reVerify: null,
}
