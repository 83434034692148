export enum AdditionalInformationPropertyType {
  array = 'array',
  boolean = 'boolean',
  enum = 'enum',
  integer = 'integer',
  number = 'number',
  object = 'object',
  phone = 'phone',
  string = 'string',
}

export enum AdditionalInformationDatePropertyFormatType {
  date = 'date',
  past_date = 'past_date',
  past_or_present_date = 'past_or_present_date',
  future_or_present_date = 'future_or_present_date',
  future_date = 'future_date',
}
export type AdditionalInformationDatePropertyFormatTypes =
  keyof typeof AdditionalInformationDatePropertyFormatType

export type AdditionalInformationSharedFields = {
  id: string
  name: string
  parentId: string | null
  title?: string | null
  label?: string | null
  required: boolean | null
  description: string | null
  valid: boolean | null
}

export type AdditionalInformationObjectProperty = AdditionalInformationSharedFields & {
  type: AdditionalInformationPropertyType.object
  properties: AdditionalInformationProperty[]
}

export type AdditionalInformationStringProperty = AdditionalInformationSharedFields & {
  type: AdditionalInformationPropertyType.string
  value?: string | null
  minLength?: number | null
  maxLength?: number | null
  format?: string | null
}

export type AdditionalInformationNumberProperty = AdditionalInformationSharedFields & {
  type: AdditionalInformationPropertyType.number
  value?: number | null
  minimum?: number | null
  maximum?: number | null
}

export type AdditionalInformationIntegerProperty = AdditionalInformationSharedFields & {
  type: AdditionalInformationPropertyType.integer
  value?: number | null
  minimum?: number | null
  maximum?: number | null
}

export type AdditionalInformationBooleanProperty = AdditionalInformationSharedFields & {
  type: AdditionalInformationPropertyType.boolean
  value?: boolean | null
}

export interface AdditionalInformationDateProperty extends AdditionalInformationStringProperty {
  format: AdditionalInformationDatePropertyFormatTypes
  value?: string | null
}

export type AdditionalInformationEnumProperty = AdditionalInformationSharedFields & {
  type: AdditionalInformationPropertyType.enum
  enum: string[]
  value?: string | null
}

export interface AdditionalInformationPhoneProperty extends AdditionalInformationStringProperty {
  format: AdditionalInformationPropertyType.phone
}

export type AdditionalInformationArrayProperty = AdditionalInformationSharedFields & {
  type: AdditionalInformationPropertyType.array
  items: { type: AdditionalInformationPropertyType.string }
  value: string[]
}

export type AdditionalInformationProperty =
  | AdditionalInformationObjectProperty
  | AdditionalInformationStringProperty
  | AdditionalInformationNumberProperty
  | AdditionalInformationIntegerProperty
  | AdditionalInformationBooleanProperty
  | AdditionalInformationArrayProperty
  | AdditionalInformationEnumProperty

export function isAdditionalInformationObjectProperty(
  property: AdditionalInformationProperty
): property is AdditionalInformationObjectProperty {
  return property.type === AdditionalInformationPropertyType.object
}
export function isAdditionalInformationStringProperty(
  property: AdditionalInformationProperty
): property is AdditionalInformationStringProperty {
  return property.type === AdditionalInformationPropertyType.string
}
export function isAdditionalInformationNumberProperty(
  property: AdditionalInformationProperty
): property is AdditionalInformationNumberProperty {
  return property.type === AdditionalInformationPropertyType.number
}
export function isAdditionalInformationIntegerProperty(
  property: AdditionalInformationProperty
): property is AdditionalInformationIntegerProperty {
  return property.type === AdditionalInformationPropertyType.integer
}
export function isAdditionalInformationBooleanProperty(
  property: AdditionalInformationProperty
): property is AdditionalInformationBooleanProperty {
  return property.type === AdditionalInformationPropertyType.boolean
}
export function isAdditionalInformationArrayProperty(
  property: AdditionalInformationProperty
): property is AdditionalInformationArrayProperty {
  return property.type === AdditionalInformationPropertyType.array
}
export function isAdditionalInformationDateProperty(
  property: AdditionalInformationProperty
): property is AdditionalInformationDateProperty {
  return (
    isAdditionalInformationStringProperty(property) &&
    Object.keys(AdditionalInformationDatePropertyFormatType).includes(property.format ?? '')
  )
}
export function isAdditionalInformationPhoneProperty(
  property: AdditionalInformationProperty
): property is AdditionalInformationPhoneProperty {
  return (
    isAdditionalInformationStringProperty(property) &&
    property.format === AdditionalInformationPropertyType.phone
  )
}
export function isAdditionalInformationEnumProperty(
  property: AdditionalInformationProperty
): property is AdditionalInformationEnumProperty {
  return property.type === AdditionalInformationPropertyType.enum
}
