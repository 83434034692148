import { Facility } from '@/types/form/prior-auth/facility'
import { Facility as MappedFacility } from '@/types/api/prior-auth/facilities/index'
import { mapAddress } from './address'

export function mapFacility(facility?: Facility | null): MappedFacility | null {
  const facilityDoesNotHaveValues = !Object.entries(facility || {}).some(([key, value]) => {
    if (key === 'address' && value) {
      return Object.values(value).some(value => !!value)
    }
    return !!value
  })

  if (!facility || facilityDoesNotHaveValues) {
    return null
  }

  return {
    in_network: facility.information?.networkStatus
      ? facility.information.networkStatus === 'IN_NETWORK'
      : null,
    name: facility?.information?.organizationName || null,
    address: mapAddress(facility?.address),
    phone: facility?.contact?.phone || null,
    fax: facility?.contact?.fax || null,
    npi: facility?.information?.npi || null,
    tin: facility?.information?.tin || null,
    contact_name: facility?.contact?.contactName || null,
    phone_ext: facility?.contact?.phoneExtension || null,
    email: facility?.contact?.email || null,
  }
}
